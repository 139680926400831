import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import SidebarSmall from '../components/SidebarSmall'
import { useWindowWidth }  from '../hooks/useWindowWidth'
import TeamMembers from '../components/TeamMembers'
import { useAuthContext } from '../hooks/useAuthContext'
import { useCollection } from '../hooks/useCollection'
import StrategyList from '../components/StrategyList'
import StrategyFilter from '../components/StrategyFilter'

export default function AllStrategies() {
  //use the useWindowWidth hook
  const { windowWidth } = useWindowWidth()

  //use the useAuthContext hook
  const { user } = useAuthContext()

  //use the useCollection hook
  const { documents, error } = useCollection('strategies') 

  //build filter
  const [currentFilter, setCurrentFilter] = useState('all')
  
  const changeFilter = (selectedFilter) => {
    setCurrentFilter(selectedFilter)
  }

  const filteredStrategies = documents ? documents.filter((eachDocument) => {
    switch (currentFilter) {
      case 'all':
        return true
      case 'my-strategies':
        let assignedToMe = false
        eachDocument.assignedUsersList.forEach((eachUser) => {
          if(user.uid === eachUser.id) {
            assignedToMe = true
          }
        })
        return assignedToMe
      case 'intraday-trading':
      case 'swing-trading':
      case 'position-trading':
      case 'longterm-investing':
        return eachDocument.style === currentFilter
      default:
        return true
    }
  }) : null

  return (
    <div className='flex h-screen pages-background'>
      <div>
        {windowWidth >= 1280 && <Sidebar />}
        {windowWidth < 1280 && <SidebarSmall />}
      </div >
      {/* Contents */}
      <div className='contents-div-container 
                      p-4 pt-5
                      sm:p-7 sm:pt-9
                      xl:p-14'>
        <h2 className='contents-page-title pb-3 sm:pb-3 lg:pb-3 xl:pb-6'>All Strategies</h2>
        {error && <p className='error'>{error}</p>}
        {documents && <div>
                        {windowWidth >= 640 && <StrategyFilter currentFilter={currentFilter} changeFilter={changeFilter} />}
                      </div>}
        {filteredStrategies && <StrategyList strategies={filteredStrategies} />}
        <div className='pb-20'></div>
      </div>
      {/* TeamMember Sidebar */}
      {user && <div>
        {windowWidth >= 640 && <TeamMembers />}
      </div>}
    </div>
  )
}
