import React, { useState } from 'react'
import { useSignup } from '../hooks/useSignup'
import '../pages/Signup.css'

export default function SignupForm() {
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailError, setThumbnailError] = useState(null)

  //use the useSignup hook
  const { signup, isPending, error } = useSignup()

  const handleSubmit = (e) => {
    e.preventDefault()
    signup(displayName, email, password, thumbnail)
  }

  const handleFileChange = (e) => {
    setThumbnail(null)
    let selectedPic = e.target.files[0]
    console.log(selectedPic)

    if (!selectedPic) {
      setThumbnailError('Please select a profile picture :)')
      return
    }
    if (!selectedPic.type.includes('image')) {
      setThumbnailError('Selected file must be an Image :)')
      return
    }
    if (selectedPic.size > 2000000) {
      setThumbnailError('File size must be less than 2MB :)')
      return
    }

    setThumbnailError(null)
    setThumbnail(selectedPic)
    console.log('profile pic updated!') 
  }

  return (
    <div>
      <form 
        onSubmit={handleSubmit}
        className=' flex flex-col w-full p-1'
      >
        <div className=' flex flex-col space-y-5 md:space-y-6 lg:space-y-4 xl:space-y-6'>
          <div className='account-input-group'>
              {/* displayName */}
              <input 
                onChange={(e) => setDisplayName(e.target.value)}
                value={displayName}
                required
                type='text'
                name='name'
                autoComplete='off'
                className='account-input w-full h-[3.2rem] md:h-[4.2rem] lg:h-[3.3rem] xl:h-[3.6rem] border-[1px] border-slate-900 dark:border-gray-300'
              />
              <label className='user-label text-sm font-light text-slate-900 dark:text-gray-200 md:text-lg lg:text-base'>first name</label>
            </div>
            <div className='account-input-group'>
              {/* email */}
              <input 
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                type='text'
                name='email'
                autoComplete='off'
                className='account-input w-full h-[3.2rem] md:h-[4.2rem] lg:h-[3.3rem] xl:h-[3.6rem] border-[1px] border-slate-900 dark:border-gray-300'
              />
              <label className='user-label text-sm font-light text-slate-900 dark:text-gray-200 md:text-base'>email</label>
            </div>
            <div className='account-input-group'>
              {/* password */}
              <input 
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                type='password'
                name='password'
                autoComplete='off'
                className='account-input w-full h-[3.2rem] md:h-[4.2rem] lg:h-[3.3rem] xl:h-[3.6rem] border-[1px] border-slate-900 dark:border-gray-300'
              />
              <label className='user-label text-sm font-light text-slate-900 dark:text-gray-200 md:text-base'>password</label>
            </div>
        </div>
        <div className='mt-5 md:mt-6 mx-auto w-[95%]'>
          <label className="block mb-2 text-[0.9rem] md:text-[1.05rem] lg:text-base font-mono text-slate-900 dark:text-gray-200" for="file_input">Upload Profile Picture:</label>
          {/* thumbnail */}
          <input 
            onChange={handleFileChange}
            className="block w-full text-[0.9rem] focus:outline-none rounded-full border
                       file:rounded-full file:border-0 file:mr-4 file:py-[0.45rem] md:file:py-3 lg:file:py-2 file:px-4 file:text-sm file:font-semibold
                       dark:file:bg-gray-50 dark:file:text-slate-900 dark:hover:file:bg-[#1a212d] dark:hover:file:text-slate-100 file:duration-200 file:transition-all file:ease-linear file:cursor-pointer
                       file:bg-[#1a212d] file:text-gray-100 hover:file:bg-slate-100 hover:file:text-slate-900
                      text-gray-900 bg-gray-200 border-gray-300 cursor-pointer 
                      dark:text-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
            id="file_input" 
            type="file"
            required
          />
          {thumbnailError && <div className='error'>{thumbnailError}</div>}
        </div>
        {!isPending && <button className="dark:bg-slate-100 dark:text-slate-900 bg-slate-900 text-slate-50 mx-auto
                             hover:scale-95 dark:hover:bg-[#1a212d] dark:hover:text-slate-100 hover:bg-slate-200 hover:text-slate-900
                             duration-200 transition-all ease-linear
                             py-3 w-full mt-10 text-base font-semibold rounded-md
                             md:py-5 md:text-xl
                             lg:w-[95%] lg:py-3
                             xl:text-lg"
                             >Sign Up</button>}
        {isPending && <button className="dark:bg-slate-100 dark:text-slate-900 bg-slate-900 text-slate-50 mx-auto
                             hover:scale-95 dark:hover:bg-[#1a212d] dark:hover:text-slate-100 hover:bg-slate-200 hover:text-slate-900
                             duration-200 transition-all ease-linear
                             py-3 w-full mt-10 text-base font-semibold rounded-md
                             md:py-5 md:text-xl
                             lg:w-[95%] lg:py-3
                             xl:text-lg"
                             disabled
                             >Loading...</button>}
        {error && <div className='error'>{error}</div>}
      </form>
    </div>
  )
}
