import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCXmAQtCMPwks2UXb2wTvsXoe2N03CbpHw",
    authDomain: "incufy.firebaseapp.com",
    projectId: "incufy",
    storageBucket: "incufy.appspot.com",
    messagingSenderId: "310846062274",
    appId: "1:310846062274:web:f14ba8c80262608203455b",
    measurementId: "G-BXRRPVN3FG"
  };

  // initialize Firebase API
  firebase.initializeApp(firebaseConfig)

  // initialize individual services
  const projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()

  // init timestamp
  const timestamp = firebase.firestore.Timestamp

  export { projectAuth, projectFirestore, projectStorage, timestamp }