import React, { useState } from 'react'
import { projectAuth } from '../firebase/config'
import { useNotification } from '../hooks/useNotification'
import { useLogout } from '../hooks/useLogout'
import firebase from 'firebase'

export default function ChangeEmail() {
  const [newEmail, setNewEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [currentPasswordEmail, setCurrentPasswordEmail] = useState('')
  const [isPendingEmail, setIsPendingEmail] = useState(false)
  const [errorEmail, setErrorEmail] = useState('')
  
  //projectAuth = firebase.auth()
  const currentUser = projectAuth.currentUser
  const credentialsEmail = firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPasswordEmail);
  
  //use the useNotification hook
  const dispatch = useNotification()
  
  //use the useLogout hook
  const { logout } = useLogout()

  const emailNotification = () => {
      dispatch({
        //type: "ADD-STRATEGY", //optional! for styling purposes only
        title: "Email Updated!",
        message: "DONE! Successfully updated your Email!"
      })
  }

  function delay(delayInms) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(2);
      }, delayInms);
    });
  }

  //change EMAIL
  const handleEmailUpdate = async (e) => {
    e.preventDefault()
    setIsPendingEmail(true)
    setErrorEmail(null)

    if(newEmail !== confirmEmail) {
      setErrorEmail('New Email and Confirm Email Do NOT Match!')
      setIsPendingEmail(false)
      return
    }

    try{
      await currentUser.reauthenticateWithCredential(credentialsEmail)
      await currentUser.updateEmail(newEmail)
      await delay(2000)
      console.log("Email Updated!")
      logout()
      setIsPendingEmail(false)
    } catch(err){
      console.log(err.message)
      setErrorEmail(err.message)
      setIsPendingEmail(false)
    }

    emailNotification()
  }

  return (
    <div>
      <form
        onSubmit={handleEmailUpdate} 
        className='flex flex-col space-y-9 p-8 dark:bg-[#202225] bg-fuchsia-50 rounded-lg w-[100%] pb-12 shadow-lg 
                   xl:space-y-14 xl:pb-[5.8rem]'
        >
        <div className='flex flex-col space-y-6 xl:space-y-7'>
          {/* new email */}
          <label className='flex flex-col space-y-1 xl:space-y-2'>
            <span className='text-sm xl:text-base font-mono'>New Email:</span>
            <input 
                className='rounded-md font-semibold text-slate-900
                        h-8 xl:h-9 p-1 xl:p-2 text-sm xl:text-base w-[100%]
                        dark:bg-gray-200 dark:focus:bg-white
                        bg-white focus:bg-white'
                required
                type='email'
                onChange={(e) => setNewEmail(e.target.value)}
                value={newEmail}
            />
          </label>
          {/* confirm email */}
          <label className='flex flex-col space-y-1 xl:space-y-2'>
              <span className='text-sm xl:text-base font-mono'>Confirm New Email:</span>
              <input 
                  className='rounded-md font-semibold text-slate-900
                            h-8 xl:h-9 p-1 xl:p-2 text-sm xl:text-base w-[100%]
                          dark:bg-gray-200 dark:focus:bg-white
                          bg-white focus:bg-white'
                  required
                  type='email'
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  value={confirmEmail}
              />
          </label>
          {/* current password */}
          <label className='flex flex-col space-y-1 xl:space-y-2'>
            <span className='text-sm xl:text-base font-mono'>Current Password To Verify:</span>
            <input 
                className='rounded-md font-semibold text-slate-900
                          h-8 xl:h-9 p-1 xl:p-2 text-sm xl:text-base w-[100%]
                        dark:bg-gray-200 dark:focus:bg-white
                        bg-white focus:bg-white'
                required
                type='password'
                onChange={(e) => setCurrentPasswordEmail(e.target.value)}
                value={currentPasswordEmail}
            />
          </label>             
        </div>
        {!isPendingEmail && <button 
          className='dark-btn duration-100 ease-linear transition-all dark:bg-amber-400 dark:text-slate-900 bg-gray-100 dark:hover:bg-amber-500 hover:bg-cyan-300 py-3 px-6 text-xs sm:text-sm xl:text-base w-[100%] mx-auto'>
            Update Email
        </button>}
        {isPendingEmail && <button 
          disabled
          className='dark-btn duration-100 ease-linear transition-all dark:bg-amber-400 dark:text-slate-900 bg-gray-100 dark:hover:bg-amber-500 hover:bg-cyan-300 py-3 px-6 text-xs sm:text-sm xl:text-base w-[100%] mx-auto animate-pulse'>
            Updating...
        </button>}
        {errorEmail && <div className='error'>{errorEmail}</div>}
      </form>
    </div>
  )
}
