import React from 'react'
import Sidebar from '../components/Sidebar'
import SidebarSmall from '../components/SidebarSmall'
import TeamMembers from '../components/TeamMembers'
import { useWindowWidth }  from '../hooks/useWindowWidth'
import { useAuthContext } from '../hooks/useAuthContext'
import ChangeEmailPassword from '../components/ChangeEmailPassword'
import ChangeEmailPasswordSmall from '../components/ChangeEmailPasswordSmall'

export default function Settings() {

  //use the useWindowWidth hook
  const { windowWidth } = useWindowWidth()

  //use the useAuthContext hook
  const { user } = useAuthContext()


  return (
    <div className='flex h-screen pages-background'>
      <div>
        {windowWidth >= 1280 && <Sidebar />}
        {windowWidth < 1280 && <SidebarSmall />}
      </div>
      {/* settings container */}
      <div className='contents-div-container p-2 lg:p-3 lg:pl-6 xl:pl-9'>
        <h2 className='contents-page-title pt-[1.5rem] lg:pt-[2rem] pl-2 sm:pl-[1.5rem] lg:pl-[3rem]'>User Settings</h2>
        {windowWidth >= 1280 && 
          <div className='mt-6 pt-[1.2rem] pl-[3rem]'>
            <ChangeEmailPassword />
          </div>
        }
        {windowWidth < 1280 && 
          <div className='mt-2 sm:mt-6 pt-[1.2rem] px-1 sm:px-[1.5rem]'>
            <ChangeEmailPasswordSmall />
          </div>
        }        
      </div>

      {user && <div>
        {windowWidth >= 640 && <TeamMembers />}
      </div>}
    </div>
  )
}
