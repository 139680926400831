import React from 'react'

const filterList = ['all', 'my-strategies', 'intraday-trading', 'swing-trading', 'position-trading', 'longterm-investing']

export default function StrategyFilter({ currentFilter, changeFilter }) {

  const handleClick = (selectedFilter) => {
    changeFilter(selectedFilter)
  }

  return (
    <div className='flex items-center dark:bg-[#202225] bg-white rounded-lg shadow-lg h-[4rem] lg:h-[3.5rem]
                    mt-3 mb-4 p-2 pl-4'>
      <nav className='flex text-xs lg:text-sm group space-x-2'>
        {filterList.map((eachFilter) => (
          <button 
            key={eachFilter}
            onClick={() => handleClick(eachFilter)}
            className={currentFilter === eachFilter 
                        ? 'dark:text-white text-black p-2 dark:bg-[#36393e] bg-[#e9ecef] rounded-xl font-semibold cursor-pointer transition-all duration-100 ease-linear' 
                        : 'dark:text-gray-400 text-gray-500 rounded-xl p-2 font-light cursor-pointer transition-all duration-100 ease-linear hover:text-black hover:bg-[#e9ecef] dark:hover:bg-[#36393e] dark:hover:text-gray-50'}
          >
            {eachFilter}
          </button>
        ))}
      </nav>
    </div>
  )
}
