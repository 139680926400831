import React from 'react'
import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../hooks/useLogout';
import { FaChartPie } from 'react-icons/fa';
import { MdOutlineSpaceDashboard, MdAttachMoney } from 'react-icons/md'
import { VscAdd } from 'react-icons/vsc'
import { FiLogOut } from 'react-icons/fi'
import { AiFillSetting } from 'react-icons/ai'
import { NavLink } from 'react-router-dom';
import MyAvatarSmall from './MyAvatarSmall'
import './SidebarSmall.css'


export default function SidebarSmall() {
    //use the useLogout hook
    const { logout, isPending } = useLogout()

    //use the useAuthContext hook
    const { user } = useAuthContext()

  return (
    <div className='fixed top-0 left-0 h-full w-[4.5rem] sm:w-[5.5rem] dark:bg-[#202225] bg-[#f8f9fa] shadow-lg'>
      <div className='flex flex-col h-full justify-start px-2 pt-6 pb-10'>
        {/* users */}
        <div className='mx-auto flex flex-col'>
          <MyAvatarSmall photoPath={user.photoURL} />
        </div>
        {/* icons */}
        <nav className='mt-[6rem]'>
          <ul className='space-y-4'>
            <li>
                <NavLink 
                  to='/dashboard'
                  className={(state) => (state.isActive ? 'sidebar-icon-active group' : 'sidebar-icon-non-active group')}
                >
                    <MdOutlineSpaceDashboard className='sidebar-icon' />
                    
                </NavLink>
            </li>
            <li>
                <NavLink 
                  to='/whalestracker'
                  className={(state) => (state.isActive ? 'sidebar-icon-active group' : 'sidebar-icon-non-active group')}
                >
                    <MdAttachMoney className='sidebar-icon' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                  to='/allstrategies'
                  className={(state) => (state.isActive ? 'sidebar-icon-active group' : 'sidebar-icon-non-active group')}
                >
                    <FaChartPie className='sidebar-icon' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                  to='/create' 
                  className={(state) => (state.isActive ? 'sidebar-icon-active group' : 'sidebar-icon-non-active group')}
                >
                    <VscAdd className='sidebar-icon'/>
                </NavLink>
            </li>
            <li>
                <NavLink 
                  to='/settings'
                  className={(state) => (state.isActive ? 'sidebar-icon-active group' : 'sidebar-icon-non-active group')}
                >
                    <AiFillSetting className='sidebar-icon' />
                </NavLink>
            </li>
          </ul>
        </nav>
        {/* logout */}
        {!isPending && <div className='mt-auto' onClick={logout}>
          <FiLogOut className='sidebar-icon' />
        </div>}
        {isPending && <div disabled className='mt-auto' onClick={logout}>
          <FiLogOut className='sidebar-icon' />
        </div>}
      </div>
    </div>
  )
}

