import React from 'react'
import { useCollection } from '../hooks/useCollection'
import { useWindowWidth }  from '../hooks/useWindowWidth'

export default function WhalesPortfolios() {

  //use the useCollection hook
  const { documents } = useCollection('whales')

  //use the useWindowWidth hook
  const { windowWidth } = useWindowWidth()

  return (
    <div>
      <div className='grid gap-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3'>
        {documents && documents.map(eachWhale => (
            <div 
              className='p-4 rounded-lg shadow-xl flex flex-col justify-start 
                       bg-white  text-slate-900 dark:bg-[#202225] dark:text-gray-200'
              key={eachWhale.id}>
              {/* picture & name */}
              <div className='flex items-center'>
                <img 
                    src={eachWhale.profilepic} 
                    className='h-14 w-14 lg:h-16 lg:w-16 xl:h-20 xl:w-20 rounded-full ring-4 dark:ring-amber-500 ring-cyan-500' 
                    alt='' 
                />
                <div className='flex flex-col border-l-4 justify-center h-[80%] ml-5 pl-3'>
                    <h2 className='text-lg font-semibold'>{eachWhale.name}</h2>
                    <h4 className='text-xs sm:text-sm font-light'>{eachWhale.fund}</h4>
                    <h4 className='text-xs sm:text-sm font-light'>AUM: <span className='font-semibold dark:text-green-400 text-emerald-400'>${eachWhale.aum} M</span></h4>
                </div>
              </div>
              {/* portfolio */}
              <div className='flex justify-between p-1 pt-6'>
                <div className='flex flex-col min-w-[15%]'>
                    <p className='font-mono font-semibold text-sm pb-1 text-fuchsia-500 dark:text-fuchsia-400'>Ticker</p>
                    {eachWhale.positions && eachWhale.positions.map(eachPosition => (
                        <p className='text-[0.8rem] font-semibold pt-2'>{eachPosition.ticker}</p>
                    ))}
                </div>
                {windowWidth >= 640 && <div className='flex flex-col min-w-[40%]'>
                    <p className='font-mono font-semibold text-sm pb-1 pl-2 text-fuchsia-500 dark:text-fuchsia-400'>Company Name</p>
                    {eachWhale.positions && eachWhale.positions.map(eachPosition => (
                        <div className='text-[0.8rem] font-light pt-2 pl-2'>{eachPosition.name}</div>
                    ))}
                </div>}
                <div className='flex flex-col min-w-[30%] items-end'>
                    <p className='font-mono font-semibold text-sm pb-1 text-fuchsia-500 dark:text-fuchsia-400'>Holdings</p>
                    {eachWhale.positions && eachWhale.positions.map(eachPosition => (
                        <p className='text-[0.8rem] font-semibold pt-2 pr-2'><span className='font-light'>$</span> {eachPosition.holding} m</p>
                    ))}
                </div>
                <div className='flex flex-col min-w-[15%] items-end'>
                    <p className='font-mono font-semibold text-sm pb-1 pr-2 text-fuchsia-500 dark:text-fuchsia-400'>%</p>
                    {eachWhale.positions && eachWhale.positions.map(eachPosition => (
                        <p className='text-[0.8rem] font-semibold pt-2 pr-2'>{eachPosition.percent}</p>
                    ))}
                </div>

              </div>
            </div>
        ))}
      </div>
    </div>
  )
}
