import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { NotificationProvider } from './notification/NotificationProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <AuthContextProvider>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </AuthContextProvider>
  //</React.StrictMode>
);


/* 

(DONE) 1. IMPORTANT - DnD for small screens
(DONE) 2. delete ideas col timer
(DONE) 3. delete col style
(DONE) 4. SETTINGS - change password
5. SETTINGS - update profile picture and username
6. Whales Portfolios
7. phone screen side bar small

*/