import React from 'react'

export default function MyAvatarSmall({ photoPath }) {
    return (
        <div className='inline-block w-[3.3rem] h-[3.3rem] rounded-full overflow-hidden ring-4 ring-green-400'>
            <img 
                src={photoPath} 
                className="w-full h-full" 
                alt='avatar' 
            />
        </div>
      )
}
