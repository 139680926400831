import React from 'react'
import Sidebar from '../components/Sidebar'
import SidebarSmall from '../components/SidebarSmall'
import { useWindowWidth }  from '../hooks/useWindowWidth'
import TeamMembers from '../components/TeamMembers'
import { useAuthContext } from '../hooks/useAuthContext'
import { Link, useParams } from 'react-router-dom'
import { useDocument } from '../hooks/useDocument'
import ProjectSummary from '../components/ProjectSummary'
import {IoMdArrowBack} from 'react-icons/io'
import StrategyComments from '../components/StrategyComments'

export default function Strategy() {
  //use the useWindowWidth hook
  const { windowWidth } = useWindowWidth()

  //use the useAuthContext hook
  const { user } = useAuthContext()

  //use the useParams hook
  const { id } = useParams()

  //use the useDocument hook
  const { document, error } = useDocument('strategies', id)


  return (
    <div className='flex h-screen pages-background'>
      <div>
        {windowWidth >= 1280 && <Sidebar />}
        {windowWidth < 1280 && <SidebarSmall />}
      </div >

      {/* Contents */}
      <div className='h-screen flex flex-col space-y-6
                      lg:flex-row lg:space-x-5 lg:space-y-14 xl:space-y-[4.3rem]
                      w-[80%] ml-[4.6rem] sm:ml-[5.5rem] sm:mr-[12rem] lg:ml-[12.7rem] xl:ml-[14rem]                       
                      p-4 pt-5
                      sm:p-9 sm:pt-6
                      lg:pt-8
                      xl:p-14'>
        {/* details */}
        <div className='w-full lg:w-[65%]'>
          <h2 className='contents-page-title pb-5 sm:pb-6 lg:pb-7 xl:pb-9'>Strategy Details</h2>
          <Link 
            to={'/allstrategies'} 
            className="flex lg:hidden items-center w-[6rem] px-[1.2rem] md:px-[0.8rem] py-1 dark-btn
                      duration-100 ease-linear transition-all group
                    dark:bg-gray-200 dark:text-slate-900 bg-gray-50 dark:hover:bg-amber-400 hover:bg-cyan-400
                      mb-3 -mt-2">
            <IoMdArrowBack className='h-4 w-4 md:h-5 md:w-5 group-hover:translate-x-[-0.3rem] duration-100 ease-linear transition-all' />
            <p className='text-xs md:text-sm group-hover:translate-x-[-0.3rem] duration-100 ease-linear transition-all'>Back</p>
          </Link>
          {error && <div className='error'>{error}</div>}
          {document && <ProjectSummary selectedStrategy={document} />}
          <Link 
            to={'/allstrategies'} 
            className="hidden lg:flex items-center w-[6rem] px-[1.2rem] md:px-[0.8rem] py-1 dark-btn
                      duration-100 ease-linear transition-all group
                    dark:bg-gray-200 dark:text-slate-900 bg-gray-50 dark:hover:bg-amber-400 hover:bg-cyan-400
                      mt-4
                      xl:mt-6">
            <IoMdArrowBack className='h-4 w-4 md:h-5 md:w-5 group-hover:translate-x-[-0.3rem] duration-100 ease-linear transition-all' />
            <p className='text-xs md:text-sm group-hover:translate-x-[-0.3rem] duration-100 ease-linear transition-all'>Back</p>
          </Link>
          <div className='pb-20'></div>
        </div>
        {/* comments */}
        <div className='w-full pb-12
                        lg:w-[35%]'>
          {document && <StrategyComments selectedStrategy={document}/>}
          <div className='pb-20'></div>
        </div>
      </div>
      

      {/* TeamMember Sidebar */}
      {user && <div>
        {windowWidth >= 640 && <TeamMembers />}
      </div>}
    </div>
  )
}
