//this is a CONTEXT
import { createContext, useReducer } from 'react'
import Notification from './Notification'
import './Notification.css'

export const NotificationContext = createContext()

const notificationHandler = (state, action) => {
  switch(action.type) {
    case "ADD_NOTIFICATION":
        return [...state, {...action.payload}];

    case "REMOVE_NOTIFICATION":
        return state.filter(eachElement => eachElement.id !== action.id);//keep the True, filter out the False
    
    default:
        return state
}
}

export const NotificationProvider = (props) => {
  const [state, dispatch] = useReducer(notificationHandler, [])

  return (
    <NotificationContext.Provider value={dispatch}>
      <div className='fixed top-2 right-6 w-[310px] z-20'>
        {state.map(eachNote => {
          return <Notification dispatch={dispatch} key={eachNote.id} {...eachNote} />
        })}
      </div>
      {props.children}
    </NotificationContext.Provider>
  )
}
