import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
import './App.css';

// import Pages & Components
import LandingPage from './pages/LandingPage'
import Dashboard from './pages/Dashboard'
// import Login from './pages/Login'
// import Signup from './pages/Signup'
import Create from './pages/Create'
import AllStrategies from './pages/AllStrategies'
import Whale from './pages/Whale';
import Settings from './pages/Settings';
import Strategy from './pages/Strategy';
import SignupNew from './pages/SignupNew';
import LoginNew from './pages/LoginNew';

function App() {
  // use the useAuthContext() hook
  const { user, authIsReady } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route 
                path='/' 
                element={ !user ? <LandingPage /> : <Navigate to='/dashboard' /> } 
            />
            <Route 
                path='/dashboard' 
                element={ user ? <Dashboard /> : <Navigate to='/login' /> } 
            />
            <Route 
                path='/login' 
                element={ user ? <Navigate to='/dashboard' /> : <LoginNew /> } 
            />
            <Route 
                path='/signup' 
                element={ user ? <Navigate to='/dashboard' /> : <SignupNew /> } 
            />
            <Route 
                path='/create' 
                element={ user ? <Create /> : <Navigate to='/login' /> } 
            />
            <Route 
                path='/allstrategies' 
                element={ user ? <AllStrategies /> : <Navigate to='/login' /> } 
            />
            <Route 
                path='/strategy/:id' 
                element={ user ? <Strategy /> : <Navigate to='/login' /> } 
            />
            <Route 
                path='/whalestracker' 
                element={ user? <Whale /> : <Navigate to='/login' /> } 
            />
            <Route 
                path='/settings' 
                element={ user ? <Settings /> : <Navigate to='/login' /> } 
            />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
