import React from 'react'
import heroImg from '../assets/hero_1.png'
import icon_1 from '../assets/icon_1.png'
import icon_2 from '../assets/icon_2.png'
import icon_3 from '../assets/icon_3.png'
import quote from '../assets/fylo/bg-quotes.png'
import profile_1 from '../assets/profile_1.png'
import profile_2 from '../assets/profile_2.png'
import profile_3 from '../assets/profile_3.png'
import bgImg from '../assets/bg-boost-desktop.svg'
import { useEffect, useState } from 'react';
import { FaMoon, FaSun, FaDiceD6, FaChartPie, FaChartLine } from 'react-icons/fa'
import { AiFillFacebook, AiFillYoutube, AiFillSliders } from 'react-icons/ai'
import { BsTwitter, BsInstagram } from 'react-icons/bs'
import GetStartedBtn from '../components/GetStartedBtn'
import GetStartedBtnLight from '../components/GetStartedBtnLight'
import { NavLink } from 'react-router-dom'


export default function LandingPage() {
  //create Dark/Light Mode button
  const [mode, setMode] = useState('');

  useEffect(() => {
    if(window.matchMedia('prefers-color-scheme:dark').matches) {
        setMode('dark');
    } else setMode('light');
  }, [])

  useEffect(() => {
    if(mode === 'dark') {
        document.documentElement.classList.remove('dark');
        
    } else {
        document.documentElement.classList.add('dark');
        
    }
  }, [mode])

  const handleModeSwitch = () => {
    setMode(mode === 'dark' ? 'light' : 'dark')
    localStorage.setItem('color-theme', JSON.stringify(mode));
    console.log(JSON.parse(localStorage.getItem('color-theme')))
  }

  const testBackground = {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }

  return (
    <div>
      {/* -- Header -- */}
      <header className="container flex mx-auto px-6 mt-[1.5rem] lg:px-12 lg:mt-9 xl:mt-[2.4rem] sm:px-9 text-center">
        {/* logo */}
        <div className='flex justify-start items-center w-[15rem] space-x-3 md:mx-0 cursor-pointer z-30'>
          <FaDiceD6 className='h-10 w-10 xl:h-12 xl:w-12' />
          <h1 className='text-4xl xl:text-5xl font-bold'>Inncufy</h1>
        </div>
        {/* -- nav -- */}
        <div className="ml-auto flex justify-center items-center space-x-6 lg:space-x-8">
          {/* dark/light mode button */}
          <button 
            className="z-30"
            onClick={handleModeSwitch}
            type='button'
          >
            {(mode === 'dark') && (<FaMoon className='w-7 h-7 xl:w-8 xl:h-8 text-slate-900 hover:text-emerald-400 duration-200 hover:scale-110' />)}
            {(mode === 'light') && (<FaSun className='w-7 h-7 xl:w-8 xl:h-8 text-slate-50 hover:text-yellow-400 duration-200 hover:scale-110' />)}
          </button>
          
          {/* login & signup */}
          <nav className="hidden sm:flex">
            <ul className='flex  items-center justify-center space-x-6'>
              <li className="group cursor-pointer">
                <NavLink to='/login'>
                  <p className="text-base xl:text-lg font-semibold">Sign in</p>
                  <div className='mx-1 group-hover:border-b dark:group-hover:border-slate-50 group-hover:border-slate-900 duration-100'></div>
                </NavLink>
              </li>
              <li className="dark:bg-slate-100 dark:text-slate-900 bg-slate-900 text-slate-50 text-base xl:text-lg font-semibold rounded-md hover:scale-95 duration-300 px-5 py-2 cursor-pointer">
                <NavLink to='/signup'>
                  TRY FOR FREE
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* hamburger button */}
          <button 
            className='block hamburger mt-[0.35rem] sm:hidden focus:outline-none hover:scale-[105%] duration-200 z-30' 
            id='menu-btn' 
            type='button' 
            onClick={window['change']}
          >
            <span class="hamburger-top dark:bg-slate-50 bg-slate-900"></span>
            <span class="hamburger-middle dark:bg-slate-50 bg-slate-900 mt-[1px]"></span>
            <span class="hamburger-bottom dark:bg-slate-50 bg-slate-900 mt-[2px]"></span>            
          </button>
        </div>
        {/* mobile menu */}
        <div 
          className='fixed inset-0 space-y-4 z-20 hidden flex-col items-center self-end w-full h-full m-h-screen px-6 py-1 pt-[10rem] pb-4 tracking-widest text-white uppercase opacity-95 dark:bg-mobileBlue bg-slate-300' 
          id='menu'
        >
          <div className="w-full py-4">
            <NavLink 
              to='/login' 
              className="dark-btn duration-100 ease-linear transition-all
              dark:bg-gray-200 bg-gray-50 dark:text-slate-900 text-slate-900 dark:hover:bg-amber-400 hover:bg-cyan-300
                py-4 px-[8rem] text-sm w-[100%] mx-auto"
            >
              Login
            </NavLink>
          </div>
          <div className="w-full py-4">
            <NavLink 
              to='/signup' 
              className="dark-btn mt-5 duration-100 ease-linear transition-all
              dark:bg-gray-200 bg-gray-50 dark:text-slate-900 text-slate-900 dark:hover:bg-amber-400 hover:bg-cyan-300
                py-4 px-[4.8rem] text-sm w-[100%] mx-auto"
            >
              Sign Up for FREE!
            </NavLink>
          </div>
        </div>
      </header>

      {/* -- Hero Section -- */}
      <section id="hero" className="bg-curvy-light-mode dark:bg-curvy-dark-mode bg-no-repeat bg-contain bg-bottom mt-3 lg:mt-12 xl:mt-10 sm:h-screen">
        {/* hero container */}
        <div className="container flex flex-col lg:flex-row mx-auto px-3 text-center pt-4 lg:pt-8">
            <img className="lg:hidden mx-auto" src={heroImg} alt="" />
            <div className='flex flex-col space-y-6 sm:space-y-12 lg:space-y-6 justify-center items-center lg:w-1/2 lg:items-start'>
              <h1 className="max-w-2xl text-[2.2rem] font-bold mt-6 leading-[2.9rem]
                             sm:text-5xl 
                             md:leading-[3.6rem] md:text-[3.2rem] 
                             lg:leading-[4.2rem] lg:mt-0 lg:ml-10 lg:text-left lg:text-[3.5rem] 
                             xl:text-[3.9rem]"
                             >
                              Build <span className='dark:text-cyan-400 text-teal-500'>Winning</span> Trading Strategies with <span className='dark:text-cyan-400 text-teal-500'>Like-Minded</span> Traders
              </h1>
              <p className=" max-w-[22rem] md:max-w-xl text-sm md:text-lg font-light px-6 lg:pr-12 lg:ml-5 lg:text-left lg:max-w-2xl lg:text-xl dark:text-gray-200">
                  With all your ideas, tools and communication in one place, you can build your Trading Strategies and Portfolios faster and more flexibly than ever before.
              </p>
              {/* get started button */}
              <NavLink to='/signup'>
                <div className='lg:ml-10 lg:mt-5'>
                  {mode === 'light' ? <GetStartedBtn /> : <GetStartedBtnLight /> }  
                </div>
              </NavLink>
            </div>
            <img className="hidden mx-auto lg:block lg:w-1/2" src={heroImg} alt="" />
        </div>
        
      </section>
      
      {/* -- Feature Section -- */}
      <section id="features" className=" dark:bg-darkBlue1 bg-gray-50 pt-[9rem] lg:pt-0 lg:-mt-[4rem]">
        {/* main container */}
        <div className="mb-20 mx-auto px-6 pb-[4rem] lg:pb-[9rem]">
          {/* row 1 */}
          <div className='flex flex-col -ml-[1rem] justify-center items-start space-x-0 space-y-6 
                          lg:flex-row lg:space-y-0
                          lg:container lg:mx-auto lg:justify-center lg:items-center lg:space-x-20'
                          >
            <img 
              className='w-[100%] -ml-[1rem]
                         sm:w-[80%]
                         md:-ml-[1.2rem] md:w-[80%]
                         lg:w-5/12 lg:ml-0' 
              src={icon_1} 
              alt='row one' 
            />
            <div className='w-11/12 -ml-[2rem] flex flex-col space-y-5
                            sm:w-[60%]
                            md:w-[75%] md:py-10 
                            lg:w-4/12 lg:py-0'
                            >
              <h3 className='text-3xl font-sans font-bold sm:text-4xl md:text-4xl lg:text-4xl'>Solo Trading Is <span className='text-red-400'>Costly</span></h3>
              <p className='text-[0.95rem] font-light text-gray-800 dark:text-gray-200 sm:text-base md:text-lg lg:text-lg'>One of the biggest mistakes most new traders make is completely isolating their trading activities from others. By doing so, you may not be able to find your trading "blind-spots", both psychologically and technically, which could cost you a fortune. </p>
            </div>
          </div>

          {/* row 2 */}
          <div className='mt-16 flex flex-col -mr-[1.5rem] justify-center items-end space-x-0 space-y-6 
                          lg:flex-row lg:space-y-0
                          lg:container lg:mx-auto lg:justify-center lg:items-center lg:space-x-20'
                          >
            <img 
              className='w-full 
                         sm:w-[80%]
                         md:w-[80%]
                         lg:hidden
                         ' 
              src={icon_2} 
              alt='row one' 
            />
            <div className='w-11/12 -mr-[2rem] flex flex-col space-y-5
                            sm:w-[60%]
                            md:w-[75%] md:py-10 
                            lg:w-4/12 lg:py-0'
                            >
              <h3 className='text-3xl font-sans font-bold first-letter sm:text-4xl md:text-4xl lg:text-4xl'>Trade with "<span className='text-teal-400'>Cell-Groups</span>"</h3>
              <p className='text-[0.95rem] font-light pr-3 text-gray-800 dark:text-gray-200 sm:text-base md:text-lg lg:text-lg'>When you build a trading strategy or a portfolio with a groupd of like-minded people who share the same trading styles, time horizons and risk tolerance, you will achieve much higher potential returns with much lower downside risk.</p>
            </div>
            <img 
              className='hidden
                         lg:block md:-ml-[1.2rem] md:w-[60%]
                         lg:w-5/12 lg:ml-0' 
              src={icon_2} 
              alt='row one' 
            />
          </div>

          {/* row 3 */}
          <div className='mt-16 flex flex-col -ml-[1rem] justify-center items-start space-x-0 space-y-6 
                          lg:flex-row lg:space-y-0
                          lg:container lg:mx-auto lg:justify-center lg:items-center lg:space-x-20'
                          >
            <img 
              className='w-[100%] -ml-[1rem]
                         sm:w-[80%]
                         md:-ml-[1.2rem] md:w-[80%]
                         lg:w-5/12 lg:ml-0' 
              src={icon_3} 
              alt='row one' 
            />
            <div className='w-11/12 -ml-[2rem] flex flex-col space-y-5
                            sm:w-[60%]
                            md:w-[75%] md:py-10 
                            lg:w-4/12 lg:py-0'
                            >
              <h3 className='text-3xl font-sans font-bold sm:text-4xl md:text-4xl lg:text-4xl'>Achieve Consistant Trading Profits <span className='text-cyan-400'>Faster</span></h3>
              <p className='text-[0.95rem] font-light text-gray-800 dark:text-gray-200 sm:text-base md:text-lg lg:text-lg'>One of the biggest mistakes most new traders make is completely isolating their trading activities from others. By doing so, you may not be able to find your trading "blind-spots", both psychologically and technically, which could cost you a fortune. </p>
            </div>
          </div>

        </div>
      </section>

      {/* -- Highlights Section -- */}
      <section id="highlights" className='py-24 -mt-[5rem] bg-slate-200 dark:bg-darkBlue'>
        <div className='container mx-auto px-3'>
          <h2 className='text-4xl xl:text-5xl mb-6 font-bold text-center text-slate-900 dark:text-slate-100'>Feature Highlights</h2>
          <p className='max-w-[18rem] mx-auto text-center text-slate-700 dark:text-slate-300 md:max-w-md'>With Inncufy, you and your team can Track the "Smart Money" Portfolios, Create Trading Strategies and Manage Trade Ideas with peace of mind.</p>
        </div>
      </section>
      <section className='pb-[10rem] bg-slate-200 dark:bg-darkBlue'>
        <div className='relative container flex flex-col items-start px-6 mx-auto md:flex-row md:space-x-7'>
          {/* horizontal line */}
          <div className='hidden absolute top-24 w-10/12 left-16 h-3 dark:bg-amber-500 bg-cyan-400 md:block'></div>
          {/* vertical line */}
          <div className='absolute w-2 left-1/2 h-full -ml-1 dark:bg-amber-500 bg-cyan-400 md:hidden'></div>

          {/* box 1 */}
          <div className='relative flex flex-col p-6 space-y-6 bg-gray-100 dark:bg-gray-100 rounded-lg md:w-1/3'>
            {/* logo position */}
            <div className='absolute -ml-10 left-1/2 -top-10 md:left-16'>
              <div className='flex items-center justify-center w-20 h-20 p-3 shadow-xl rounded-full bg-fuchsia-700'>
                <FaChartPie className='h-[3rem] w-[3rem] dark:text-slate-100 text-slate-100'/>
              </div>
            </div>
            <h4 className='pt-6 text-xl dark:text-slate-900 font-bold text-center capitalize md:text-left'>
              Track "Whales" Portfolios
            </h4>
            <p className='text-center text-gray-700 md:text-left'>
              Incufy allows you to track the TOP 10 holdings of the biggest Fund Managers on Wall Street like Bill Ackman, Warren Buffett, Steve Cohen, George Soros and many more.
            </p>
          </div>

          {/* box 2 */}
          <div className='relative flex flex-col mt-24 p-6 space-y-6 bg-gray-100 dark:bg-gray-100 rounded-lg md:mt-8 md:w-1/3'>
            {/* logo position */}
            <div className='absolute -ml-10 left-1/2 -top-10 md:left-16'>
              <div className='flex items-center justify-center w-20 h-20 p-3 shadow-xl rounded-full bg-fuchsia-700'>
                <FaChartLine className='h-[3rem] w-[3rem] dark:text-slate-100 text-slate-100'/>
              </div>
            </div>
            <h4 className='pt-6 text-xl dark:text-slate-900 font-bold text-center capitalize md:text-left'>
              Create Winning Strategies
            </h4>
            <p className='text-center text-gray-700 md:text-left'>
              You and your "Trading Cell Group" can Create trading strategies, input all the details and assign group members for any tasks such as Backtesting or Data Retrieving.
            </p>
          </div>

          {/* box 3 */}
          <div className='relative flex flex-col mt-24 p-6 space-y-6 bg-gray-100 dark:bg-gray-100 rounded-lg md:mt-16 md:w-1/3'>
            {/* logo position */}
            <div className='absolute -ml-10 left-1/2 -top-10 md:left-16'>
              <div className='flex items-center justify-center w-20 h-20 p-3 shadow-xl rounded-full bg-fuchsia-700'>
                <AiFillSliders className='h-[3rem] w-[3rem] dark:text-slate-100 text-slate-100' />
              </div>
            </div>
            <h4 className='pt-6 text-xl dark:text-slate-900 font-bold text-center capitalize md:text-left'>
              Manage Trade Ideas
            </h4>
            <p className='text-center text-gray-700 md:text-left'>
              With extremely intuitive Drag-n-Drop system, you can easily collect all the Trade Ideas that come across your mind and manage them with ease so that you will never miss any profitable opportunities.
            </p>
          </div>

        </div>      
      </section>

      {/* -- Testimonials Section -- */}
      <section 
        id="testimonials" 
        className=" pt-[6rem] bg-grayishViolet dark:bg-darkViolet"
        style={testBackground}
      >
        {/* main container */}
        <div className="lg:container mx-auto px-6 md:pt-6 md:px-12 pb-[20rem] md:pb-[18rem] lg:pb-[20rem]">
          {/* boxes container */}
          <div className="relative flex flex-col lg:flex-row w-full space-y-6 lg:space-y-0 lg:space-x-12">

            {/* quote image */}
            <img className="absolute left-1 top-2 w-10 animate-pulse
                            md:-top-10 md:left-1 md:w-20
                            lg:-top-12 lg:left-12" 
                 src={quote} alt="" />

            {/* box 1 */}
            <div className=" flex flex-col p-10 space-y-6 rounded-lg shadow-xl bg-gray-100 dark:bg-darkBlue3 lg:w-1/3">
                <p className="text-sm leading-6 md:text-lg">
                    The secret to being successful from a Trading perspective is to have an indefatigable and unquenchable thirst for information, knowledge and wisdom from different market participants.
                </p>
                <div className="flex space-x-4">
                    <img className="w-12 h-12 rounded-full ring-2 ring-accentCyan" src={profile_1} alt="" />
                    <div>
                        <h5 className="text-sm font-semibold">Ernest Chan</h5>
                        <p className="text-sm font-extralight opacity-70">Founder. Predictnow.ai</p>
                    </div>
                </div>
            </div>
            {/* box 2 */}
            <div className=" flex flex-col p-10 space-y-6 rounded-lg shadow-xl bg-gray-100 dark:bg-darkBlue3 lg:w-1/3">
                <p className="text-sm leading-6 md:text-lg">
                    Great people. Great teamwork. Open environment. Get everyone work on the same goals. That made a lot of money. I disparage some for whom model-making was a solo job or a part-time hobby.
                </p>
                <div className="flex space-x-4">
                    <img className="w-12 h-12 rounded-full ring-2 ring-accentCyan" src={profile_2} alt="" />
                    <div>
                        <h5 className="text-sm font-semibold">Jim Simons</h5>
                        <p className="text-sm font-extralight opacity-70">Founder. Renaissance Technologies</p>
                    </div>
                </div>
            </div>
                {/* box 3 */}
                <div className=" flex flex-col p-10 space-y-6 rounded-lg shadow-xl bg-gray-100 dark:bg-darkBlue3 lg:w-1/3">
                <p className="text-sm leading-6 md:text-lg">
                    Don’t be a hero. Always question yourself and your ability. Don’t ever feel that you are good enough to Trade consistantly on your own. The second you do, you are dead.
                </p>
                <div className="flex space-x-4">
                    <img className="w-12 h-12 rounded-full ring-2 ring-accentCyan" src={profile_3} alt="" />
                    <div>
                        <h5 className="text-sm font-semibold">Paul Tudor Jones</h5>
                        <p className="text-sm font-extralight opacity-70">Founder. Tudor Investment Corporation</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>

      {/* -- Early Access Section -- */}
      <section id="early-access" className="relative px-6 dark:bg-darkBlue2 bg-slate-600 md:px-0">
        {/* box container */}
        <div className="relative shadow-xl -top-40 max-w-4xl mx-auto p-10 px-6 space-y-6 text-center rounded-lg bg-gray-200 dark:bg-darkBlue1 md:px-16">
            <h5 className="text-2xl md:text-3xl font-bold">Get Early Access Today</h5>
            <p className="text-sm">
                It only takes a minute to sign up and our free starter tier is extremely generous. If you have any questions, our support team would be happy to help you.
            </p>
            {/* input & button */}
            <div className="flex flex-col items-start justify-center space-y-6 md:flex-row md:space-y-0 md:space-x-6">
                <div className="w-full md:flex-1">
                    <input 
                        className="w-full px-14 py-3 rounded-full focus:outline-none text-gray-600" 
                        type="text" 
                        placeholder="email@example.com" 
                        required
                    />
                </div>
                <button className="bg-teal-400 text-slate-700 dark:text-white dark:bg-cyan-500 font-semibold w-full md:w-56 p-3 px-6 rounded-full hover:scale-95 duration-300">
                    Submit
                </button>
            </div>
        </div>
      </section>

      {/* -- Footer Section -- */}
      <footer className='py-16 dark:bg-darkBlue2 bg-slate-600 z-20 -mt-[3rem] lg:mt-0'>
        <div className='container flex flex-col items-center justify-between mx-auto space-y-16 
                        lg:flex-row lg:space-y-0 lg:items-start'>
          {/* logo */}
          <div className='flex justify-center items-center w-56 space-x-2 md:mx-0 cursor-pointer'>
            <FaDiceD6 className='h-8 w-8 xl:h-10 xl:w-10 text-gray-200' />
            <h1 className='text-3xl xl:text-4xl font-bold text-gray-200'>Inncufy</h1>
          </div>

          {/* menus container */}
          <div className='flex flex-col space-y-16 md:space-x-20 md:flex-row md:space-y-0'>
            {/* menu 1 */}
            <div className='flex flex-col items-center w-full md:items-start'>
              <div className='mb-5 text-lg font-bold text-slate-100 capitalize'>Features</div>
              <div className='flex flex-col items-center space-y-3 md:items-start'>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Track Whale's Porfolios
                </p>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Share Trade Ideas
                </p>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Create Trading Strategies
                </p>
              </div>
            </div>
            {/* menu 2 */}
            <div className='flex flex-col items-center w-full md:items-start'>
              <div className='mb-5 text-lg font-bold text-slate-100 capitalize'>Resources</div>
              <div className='flex flex-col items-center space-y-3 md:items-start'>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  API
                </p>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Developers
                </p>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Support
                </p>
              </div>
            </div>
            {/* menu 3 */}
            <div className='flex flex-col items-center w-full md:items-start'>
              <div className='mb-5 text-lg font-bold text-slate-100 capitalize'>Company</div>
              <div className='flex flex-col items-center space-y-3 md:items-start'>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Our Story
                </p>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Our Team
                </p>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Careers
                </p>
                <p className='capitalize text-sm text-slate-300 dark:text-grayishViolet dark:hover:text-cyan-400 hover:text-cyan-500 cursor-pointer duration-200'>
                  Contact
                </p>
              </div>
            </div>
          </div>

          {/* social media container */}
          <div className='flex items-center space-x-6 pt-6 lg:pt-0'>
            <AiFillFacebook className='h-8 w-8 cursor-pointer duration-200 text-slate-50 dark:text-slate-100 
            dark:hover:text-blue-400 hover:text-blue-500 hover:scale-110' />
            <BsTwitter className='h-8 w-8 cursor-pointer duration-200 text-slate-50 dark:text-slate-100 
            dark:hover:text-[#00acee] hover:text-[#00acee] hover:scale-110' />
            <AiFillYoutube className='h-8 w-8 cursor-pointer duration-200 text-slate-50 dark:text-slate-100 
            dark:hover:text-[#FF0000] hover:text-[#FF0000] hover:scale-110' />
            <BsInstagram className='h-[1.6rem] w-[1.6rem] cursor-pointer duration-200 text-slate-50 dark:text-slate-100 
            dark:hover:text-[#fccc63] hover:text-[#fccc63] hover:scale-110' />
          </div>
        </div>
      </footer>
    </div>
  )
}
