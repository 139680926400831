import React from 'react'
import { Link } from 'react-router-dom'
import GroupAvatars from './GroupAvatars'
import './StrategyList.css'

export default function StrategyList({ strategies }) {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5'>
        {strategies.length === 0 && <p>No Trading Strategies Yet!</p>}
        {strategies.map(eachStrategy => (
            <Link 
              to={`/strategy/${eachStrategy.id}`} 
              key={eachStrategy.id}
              className='p-4 rounded-lg shadow-xl flex flex-col justify-between bg-white  text-slate-900
                         dark:bg-[#202225] dark:text-gray-200
                         hover:scale-105 transition-all ease-linear duration-200'
            >
                <div>
                    <h4 className='text-base font-semibold'>{eachStrategy.strategyName}</h4>
                    <p className='text-xs mt-1'>Due by <span className='text-sm dark:text-amber-500 text-cyan-500 font-semibold'>{eachStrategy.dueDate.toDate().toDateString()}</span></p>
                </div>
                <div className='assigned-to'>
                  <p className='text-xs font-mono mt-4'>Backtesting Assigned To:</p>
                  <ul className='flex space-x-2 mt-3'>
                    {eachStrategy.assignedUsersList.map(eachUser => (
                      <li key={eachUser.photoURL} className=''>
                        <GroupAvatars photoPath={eachUser.photoURL} />
                      </li>
                    ))}
                  </ul>
                </div>
            </Link>
        ))}
    </div>
  )
}
