import React from 'react'

export default function GroupAvatars({ photoPath }) {

  return (
    <div className='inline-block w-[42px] h-[42px] rounded-full overflow-hidden'>
        <img 
            src={photoPath} 
            className="w-full h-full" 
            alt='avatar' 
        />
    </div>
  )
}
