import React from 'react'
import { useCollection } from '../hooks/useCollection'
import DarkLightModeBtnWithHook from './DarkLightModeBtnWithHook'
import GroupAvatars from './GroupAvatars'

export default function TeamMembers() {
  //use the useCollection hook
  const { error, documents } = useCollection('users') 

  return (
    <div className='fixed top-0 right-0 w-[12rem] min-w-[12rem] h-full p-6 dark:bg-[#202225] bg-[#f8f9fa] overflow-y-auto'>
      <div className='justify-end items-center space-x-8 flex'>
        <DarkLightModeBtnWithHook />
      </div>  
      <h2 className='text-right text-lg mt-[20px] pb-[10px] border-b-2 border-b-slate-400 font-semibold'>Group Members</h2>
      {error && <div className='error'>{error}</div>}
      {documents && documents.map(eachUser => (
        <div key={eachUser.id} className='flex justify-end items-center my-5 mx-auto space-x-3'>
          {eachUser.online && 
            <div className=" relative my-auto ml-1 h-[0.9rem] w-5 md:h-3 md:w-3 xl:h-4 xl:w-4">
              <div className='h-full w-full absolute top-0 left-0 bg-[#2dbf00] rounded-full animate-ping'></div>
              <div className='h-full w-full absolute top-0 left-0 bg-[#2dbf00] rounded-full'></div>
            </div>
          }  
          <span className='text-base font-thin'>{eachUser.displayName}</span>
          <GroupAvatars photoPath={eachUser.photoURL} />
        </div>
      ))}
    </div>
  )
}
