import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import SidebarSmall from '../components/SidebarSmall'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { useAuthContext } from '../hooks/useAuthContext'
import { useCollection } from '../hooks/useCollection'
import { useFirestore } from '../hooks/useFirestore'
import { useNavigate } from 'react-router-dom'
import { timestamp } from '../firebase/config'
import TeamMembers from '../components/TeamMembers'
import Select from 'react-select'
import './Create.css'
import { useNotification } from '../hooks/useNotification'

const strategy_styles = [
  { value: 'scalp-trading', label: 'Scalp Trading' },
  { value: 'intraday-trading', label: 'Intra-Day Trading' },
  { value: 'swing-trading', label: 'Swing Trading'},
  { value: 'position-trading', label: 'Position Trading'},
  { value: 'longterm-investing', label: 'Long-Term Investing'},
]

export default function Create() {
  const [strategyName, setStrategyName] = useState('')
  const [details, setDetails] = useState('')
  const [timeFrame, setTimeFrame] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [style, setStyle] = useState('')
  const [assignedUsers, setAssignedUsers] = useState([])
  const [formError, setFormError] = useState(null)

  //use the useWindowWidth hook
  const { windowWidth } = useWindowWidth()

  //use the useAuthContext hook
  const { user } = useAuthContext()

  //use the useCollection hook
  const { documents } = useCollection('users')

  //use the useFirestore hook
  const { addDocument, response } = useFirestore('strategies')

  //use the useNavigate hook
  const navigate = useNavigate()

  //use the useNotification hook
  const dispatch = useNotification()

  const handleNewNotification = () => {
    dispatch({
      //type: "ADD-STRATEGY", //optional! for styling purposes only
      title: "New Strategy Created!",
      message: "BOOM! Successfully created a new Strategy!"
    })
  }

  //create a selection of all users  
  const [groupMembers, setGroupMembers] = useState([])
  useEffect(() => {
    if(documents) {
      const groupMembersOptions = documents.map((eachUser) => {
        return { value: eachUser, label: eachUser.displayName }
      })
      setGroupMembers(groupMembersOptions)
    }
  }, [documents])

  //click "Add Strategy" button
  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)

    if(!style) {
      setFormError('Please select a Strategy Style')
      return
    }
    if(assignedUsers.length < 1) {
      setFormError('Please assign at least 1 user for Backtesting! You can always assign it to yourself!')
      return
    }
    const createdBy = {
      displayName: user.displayName,
      photoURL: user.photoURL,
      id: user.uid
    }
    const assignedUsersList = assignedUsers.map((eachUser) => {
      return {
        displayName: eachUser.value.displayName,
        photoURL: eachUser.value.photoURL,
        id: eachUser.value.id
      }
    })
    //create a Strategy object for database
    const newStrategy = {
      strategyName: strategyName,
      details: details,
      timeFrame: timeFrame,
      dueDate: timestamp.fromDate(new Date(dueDate)),
      style: style.value,
      comments: [],
      createdBy: createdBy,
      assignedUsersList: assignedUsersList
    }

    await addDocument(newStrategy)
    if(!response.error) {
      navigate('/allstrategies')
    }

    handleNewNotification()

  }

  return (
    <div className='flex pages-background'>
      {user && <div>
        {windowWidth >= 1280 && <Sidebar />}
        {windowWidth < 1280 && <SidebarSmall />}
      </div>}

      {/* Contents */}
      <div className='contents-create-div'>
        <h2 className='contents-page-title'>Create a New Strategy</h2>
        <form 
          onSubmit={handleSubmit}
          className='flex flex-col items-start
                     space-y-5
                     sm:space-y-8
                     lg:space-y-4
                     xl:space-y-7'
        >
          {/* strategy name */}
          <label className='flex flex-col space-y-1 min-w-[20%]
                            w-[100%]
                            sm:w-[90%] 
                            lg:w-[60%] 
                            xl:w-[50%]'>
            <span className='create-span-title'>Strategy Name:</span>
            <input 
              className='rounded-md font-medium text-slate-900
                         h-8 p-1 text-xs
                         lg:text-sm
                         xl:p-2 xl:h-9 xl:text-base
                       dark:bg-gray-200 dark:focus:bg-white
                       bg-white focus:bg-white'
              required
              type='text'
              onChange={(e) => setStrategyName(e.target.value)}
              value={strategyName}
            />
          </label>
          {/* styles */}
          <label className='flex flex-col space-y-1 min-w-[20%] 
                            w-[100%]
                            sm:w-[90%] 
                            lg:w-[60%] 
                            xl:w-[50%]'>
            <span className='create-span-title'>Strategy Style:</span>
            <Select 
              className='h-10 w-[100%] font-medium text-xs lg:text-sm xl:text-base rounded-lg text-slate-900'
              onChange={(option) => setStyle(option)}
              options={strategy_styles}
            />
          </label>
          {/* time frame */}
          <label className='flex flex-col space-y-1 min-w-[20%] 
                            w-[100%]
                            sm:w-[90%] 
                            lg:w-[60%] 
                            xl:w-[50%]'>
            <span className='create-span-title'>Ideal Timeframe:</span>
            <input
              className='rounded-md font-medium text-slate-900
                         h-8 p-1 text-xs
                         lg:text-sm
                         xl:p-2 xl:h-9 xl:text-base
                       dark:bg-gray-200 dark:focus:bg-white
                       bg-white focus:bg-white'
              required
              type='text'
              onChange={(e) => setTimeFrame(e.target.value)}
              value={timeFrame}
            />
          </label>
          {/* due date */}
          <label className='flex justify-between space-y-0 space-x-5 min-w-[20%] pb-3
                            w-[100%]
                            sm:w-[90%]  
                            lg:w-[60%] 
                            xl:w-[50%]'>
            <span className='create-span-title'>Backtesting Due Date:</span>
            <input 
              className='h-8 px-3 w-[60%] md:w-[50%] font-medium text-xs lg:text-sm xl:text-base rounded-lg text-slate-900'
              required
              type="date"
              onChange={(e) => setDueDate(e.target.value)}
              value={dueDate}
            ></input>
          </label>
          {/* assign users */}
          <label className='flex flex-col space-y-1 min-w-[20%]
                            w-[100%]
                            sm:w-[90%] 
                            lg:w-[60%]
                            xl:w-[50%]'>
            <span className='create-span-title'>Backtesting Assign To:</span>
            <Select
              className='h-9 w-[100%] font-medium text-xs lg:text-sm xl:text-base rounded-lg text-slate-900'
              onChange={(option) => setAssignedUsers(option)} 
              options={groupMembers}
              isMulti
            />
          </label>
          {/* details */}
          <label className='flex flex-col space-y-1 min-w-[20%] pb-5
                            w-[100%]
                            sm:w-[90%] 
                            lg:w-[60%] lg:pb-5
                            xl:w-[50%] xl:pb-5'>
            <span className='create-span-title'>Strategy Details:</span>
            <textarea 
              className='min-h-[8rem] rounded-md font-light text-slate-900
                         h-8 p-1 text-xs
                         lg:text-sm
                         xl:p-2 xl:h-9 xl:text-base
                         dark:bg-gray-200 dark:focus:bg-white
                         bg-white focus:bg-white'
              required
              type='text'
              onChange={(e) => setDetails(e.target.value)}
              value={details}
            ></textarea>
          </label>
          <button
             
            className='dark-btn duration-100 ease-linear transition-all
                         dark:bg-gray-200 dark:text-slate-900 bg-gray-100 dark:hover:bg-amber-400 hover:bg-cyan-300
                           py-2 px-9 text-sm w-[95%] mx-auto
                           sm:py-3 sm:w-[90%] sm:ml-0
                           md:w-[90%] md:text-base
                           lg:w-[25%] 
                           xl:w-[20%]'
                           >
              Add Strategy
          </button>
          {formError && <p className='error'>{formError}</p>}
        </form>
      </div>

      {user && <div>
        {windowWidth >= 640 && <TeamMembers />}
      </div>}

    </div>
  )
}
