import React from 'react'
import { useState } from 'react'
import { useLogin } from '../hooks/useLogin'
import '../pages/Signup.css'

export default function LoginForm() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  //use the useLogin hook
  const {login, isPending, error} = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  return (
    <div>
      <form 
        onSubmit={handleSubmit}
        className=' flex flex-col w-full p-1'
      >
        <div className=' flex flex-col space-y-5 md:space-y-6 lg:space-y-5 xl:space-y-6'>
            <div className='account-input-group'>
              {/* email */}
              <input 
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                type='text'
                name='email'
                autoComplete='off'
                className='account-input w-full h-[3.2rem] md:h-[4.2rem] lg:h-[3.3rem] xl:h-[3.6rem] border-[1px] border-slate-900 dark:border-gray-300'
              />
              <label className='user-label text-sm font-light text-slate-900 dark:text-gray-200 sm:text-base'>email</label>
            </div>
            <div className='account-input-group'>
              {/* password */}
              <input
                onChange={(e) => setPassword(e.target.value)} 
                value={password}
                required
                type='password'
                name='password'
                autoComplete='off'
                className='account-input w-full h-[3.2rem] md:h-[4.2rem] lg:h-[3.3rem] xl:h-[3.6rem] border-[1px] border-slate-900 dark:border-gray-300'
              />
              <label className='user-label text-sm font-light text-slate-900 dark:text-gray-200 sm:text-base'>password</label>
            </div>
        </div>
        {!isPending && <button 
                         className="dark:bg-slate-100 dark:text-slate-900 bg-slate-900 text-slate-50 mx-auto
                             hover:scale-95 dark:hover:bg-[#1a212d] dark:hover:text-slate-100 hover:bg-slate-200 hover:text-slate-900
                             duration-200 transition-all ease-linear
                             py-3 w-full mt-10 text-base font-semibold rounded-md
                             sm:py-5 sm:text-xl lg:text-base
                             lg:w-[100%] lg:py-3
                             xl:text-lg"
                             >Login</button>}
        {isPending && <button 
                        disabled
                        className="dark:bg-slate-100 dark:text-slate-900 bg-slate-900 text-slate-50 mx-auto
                             hover:scale-95 dark:hover:bg-[#1a212d] dark:hover:text-slate-100 hover:bg-slate-200 hover:text-slate-900
                             duration-200 transition-all ease-linear
                             py-3 w-full mt-10 text-base font-semibold rounded-md
                             sm:py-5 sm:text-xl lg:text-base
                             lg:w-[100%] lg:py-3
                             xl:text-lg"
                             >Loading...</button>}
        {error && <div className='error'>{error}</div>}
      </form>
    </div>
  )
}
