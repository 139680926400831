import React from 'react'
import GroupAvatars from './GroupAvatars'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useFirestore } from '../hooks/useFirestore'
import { useAuthContext } from '../hooks/useAuthContext'
import { useNavigate } from 'react-router-dom'
import { useNotification } from '../hooks/useNotification'

export default function ProjectSummary({ selectedStrategy }) {
  //use the useFirestore hook
  const { deleteDocument } = useFirestore('strategies')

  //use the useNavigate hook
  const navigate = useNavigate()

  //use the useNotification hook
  const dispatch = useNotification()

  const handleNewNotification = () => {
    dispatch({
      type: "DELETE-STRATEGY",
      title: "Strategy Deleted!",
      message: "Done! Successfully deleted the Strategy!"
    })
  }  

  const handleDelete = (e) => {
    deleteDocument(selectedStrategy.id)
    navigate('/allstrategies')
    handleNewNotification()
  }

  //use the useAuthContext hook
  const { user } = useAuthContext()

  return (
    <div className='bg-white text-slate-900 dark:text-gray-100 dark:bg-[#202225] rounded-lg shadow-xl
                      p-5 
                      sm:p-6
                      xl:p-8 '>
      <div className='flex flex-col'>
        {user.uid === selectedStrategy.createdBy.id && (
        <button
          onClick={handleDelete} 
          className='flex justify-end items-center space-x-1 z-10 text-red-500 dark:text-red-300
                           cursor-pointer group'>
            <RiDeleteBin6Line className='h-4 w-4 duration-200 transition-all ease-linear 
                                       dark:group-hover:text-red-500 group-hover:text-red-700 group-hover:rotate-45' />
            <p className='text-xs duration-200 transition-all ease-linear 
                        dark:group-hover:text-red-500 group-hover:text-red-700'>Delete</p>
        </button>)}
        <div className={`${user.uid === selectedStrategy.createdBy.id ? 'flex flex-col space-y-6 -mt-4' : 'flex flex-col space-y-6'}`}>
          <div>
            <h4 className='text-sm font-mono text-slate-900 dark:text-gray-300'>Strategy Name:</h4>
            <p className='text-base font-semibold mt-1'>{selectedStrategy.strategyName}</p>
            <p className='text-xs font-extralight text-gray-700 dark:text-gray-300 mt-1'>Created by: {selectedStrategy.createdBy.displayName}</p>
          </div>
          <div>
            <h4 className='text-sm font-mono text-slate-900 dark:text-gray-300'>How it works:</h4>
            <p className='mt-1 text-sm sm:text-base'>
            {selectedStrategy.details}
            </p>
          </div>
          <div>
            <p className='text-sm font-mono text-slate-900 dark:text-gray-300'>Backtesting due by</p> 
            <p className='mt-1 text-sm dark:text-amber-500 text-cyan-500 font-semibold'>{selectedStrategy.dueDate.toDate().toDateString()}</p>
          </div>
          <div>
            <h4 className='text-sm font-mono text-slate-900 dark:text-gray-300'>Backtesting assigned to:</h4>
            <div className='flex space-x-2 mt-2'>
              {selectedStrategy.assignedUsersList.map(eachUser => (
              <div key={eachUser.id}>
                  <GroupAvatars photoPath={eachUser.photoURL} />
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
