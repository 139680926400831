import React from 'react'
import Sidebar from '../components/Sidebar'
import SidebarSmall from '../components/SidebarSmall'
import TeamMembers from '../components/TeamMembers'
import { useWindowWidth }  from '../hooks/useWindowWidth'
import { useAuthContext } from '../hooks/useAuthContext'
import WhalesPortfolios from '../components/WhalesPortfolios'


export default function Whale() {
  //use the useWindowWidth hook
  const { windowWidth } = useWindowWidth()

  //use the useAuthContext hook
  const { user } = useAuthContext()

  return (
    <div className='flex h-screen pages-background'>
      <div>
        {windowWidth >= 1280 && <Sidebar />}
        {windowWidth < 1280 && <SidebarSmall />}
      </div>

      <div className='contents-div-container p-2 mt-2 sm:-mt-[0.5rem] md:mt-0 lg:mt-0 md:p-8 lg:p-6 xl:p-9'>
        {/* title */}
        <div className='flex flex-col space-y-2 pl-2 pb-6 sm:pb-8 lg:pb-10 xl:pb-12'>
          <h2 className='contents-page-title'>Whales Portfolio Tracker</h2>
          {windowWidth < 640 && <div className='flex space-x-1'>
            <div className=" relative my-auto ml-1 h-[0.9rem] w-5 md:h-3 md:w-3 xl:h-4 xl:w-4">
              <div className='h-[85%] w-[60%] absolute top-0 left-0 dark:bg-amber-500 bg-cyan-400 rounded-full animate-ping'></div>
              <div className='h-[85%] w-[60%] absolute top-0 left-0 dark:bg-amber-500 bg-cyan-400 rounded-full'></div>
            </div>
            <p className='text-xs font-mono font-light dark:text-slate-300 text-slate-700'>
              TOP 10 Holdings of the Whales
            </p>
          </div>}

          {windowWidth >= 640 && <div className='flex space-x-3'>
            <div className=" relative my-auto ml-1 h-[0.9rem] w-5 md:h-3 md:w-3 xl:h-4 xl:w-4">
              <div className='h-full w-full absolute top-0 left-0 dark:bg-amber-500 bg-cyan-400 rounded-full animate-ping'></div>
              <div className='h-full w-full absolute top-0 left-0 dark:bg-amber-500 bg-cyan-400 rounded-full'></div>
            </div>
            <p className='text-sm font-mono font-light dark:text-slate-300 text-slate-700'>
              TOP 10 Holdings of the Biggest Fund Managers on Wall Street
            </p>
          </div>}
        </div>
        {/* Contents */}
        <div className='pb-10 lg:pb-12 xl:pb-20'>
          <WhalesPortfolios />
        </div>
      </div>

      {user && <div>
        {windowWidth >= 640 && <TeamMembers />}
      </div>}

    </div>
  )
}
