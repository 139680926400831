import React from 'react'
import loginImg from '../assets/login.png'
import { NavLink } from 'react-router-dom'
import { FaDiceD6 } from 'react-icons/fa'
import './Signup.css'
import LoginForm from '../components/LoginForm'

export default function LoginNew() {

  return (
    <div className='h-screen flex justify-center items-center bg-curvy-light-mode dark:bg-curvy-dark-mode bg-no-repeat bg-contain bg-bottom '>
      {/* main container */}
      <div className='flex justify-center items-center h-[95%] lg:h-[85%] xl:h-[85%] -mt-[9rem]
                      w-[30rem]
                      md:w-[40rem] md:-mt-[9rem]
                      lg:w-[65rem] lg:space-x-8 lg:-mt-[3rem] lg:ml-[7rem]
                      xl:w-[80rem] xl:-mt-[8rem] xl:ml-[11rem]'>

        {/* sign up container */}
        <div className='flex flex-col items-center justify-center dark:bg-slate-700 bg-slate-300 rounded-xl shadow-2xl space-y-10 
                        h-[70%] w-[85%]
                        sm:space-y-14
                        lg:w-[40%] lg:h-[85%] lg:space-y-14
                        xl:h-[80%]'>
          {/* logo */}
          <div className=' flex justify-center items-center w-[75%] space-x-2 cursor-pointer -mt-[2rem]'>
            <NavLink to='/' className='flex justify-center items-center w-[75%] space-x-2 cursor-pointer hover:scale-95 duration-200'>
              <FaDiceD6 className='h-11 w-11 sm:h-12 sm:w-12 lg:h-14 lg:w-14 xl:h-14 xl:w-14' />
              <h1 className='text-4xl sm:text-5xl lg:text-5xl xl:text-5xl font-bold'>Inncufy</h1>
            </NavLink>
          </div>
          {/* info area */}
          <div className='w-[90%] sm:w-[85%] lg:w-[80%] xl:w-[75%]'>
            <LoginForm />
          </div>
          <div className='flex flex-col items-center'>
            <h2 className='text-sm sm:text-base lg:text-sm text-slate-900 dark:text-gray-300'>Don't have an account with Incufy?</h2>
            <NavLink to='/signup' className='text-slate-900 dark:text-gray-300 text-sm sm:text-base lg:text-sm transition-all duration-200 ease-linear hover:font-bold hover:text-cyan-500 dark:hover:text-cyan-500 hover:scale-105'>Sign Up HERE!</NavLink>
          </div>
        </div>
        {/* image container */}
        <div className='hidden lg:flex lg:flex-col lg:justify-center lg:items-center w-[50%] lg:w-[60%] xl:w-[60%]'>
          <img className='' src={loginImg} alt=''/>
        </div>
      </div>
    </div>
  )
}