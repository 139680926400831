import React, { useState } from 'react'
import { projectAuth } from '../firebase/config'
import { useNotification } from '../hooks/useNotification'
import { useLogout } from '../hooks/useLogout'
import firebase from 'firebase'

export default function ChangePasswordSmall() {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState('')

  //projectAuth = firebase.auth()
  const currentUser = projectAuth.currentUser
  const credentials = firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPassword);

  //use the useNotification hook
  const dispatch = useNotification()

  //use the useLogout hook
  const { logout } = useLogout()

  const passwordNotification = () => {
    dispatch({
      //type: "ADD-STRATEGY", //optional! for styling purposes only
      title: "Password Updated!",
      message: "DONE! Successfully updated your Password!"
    })
  }

  function delay(delayInms) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(2);
      }, delayInms);
    });
  }

  //Change PASSWORD
  const handlePasswordUpdate = async (e) => {
    e.preventDefault()
    setIsPending(true)
    setError(null)

    if(newPassword !== confirmPassword) {
      setError('New Password and Confirm Password Do NOT Match!')
      setIsPending(false)
      return
    }

    try{
      await currentUser.reauthenticateWithCredential(credentials)
      await currentUser.updatePassword(newPassword)
      await delay(2000)
      console.log("Password Updated!")
      logout()
      setIsPending(false)
    } catch(err){
      console.log(err.message)
      setError(err.message)
      setIsPending(false)
    }

    passwordNotification()
  }
  return (
    <div>
      <form 
        onSubmit={handlePasswordUpdate}
        className='flex flex-col space-y-12 p-4 sm:p-8 dark:bg-[#202225] bg-fuchsia-100 rounded-lg w-[100%] pb-[11rem] sm:pb-[8.5rem] shadow-lg'
        >
        <div className='flex flex-col space-y-8 mt-3'>
          {/* current password */}
          <label className='flex flex-col space-y-2'>
            <span className='text-xs sm:text-sm font-mono'>Current Password:</span>
            <input 
                className='rounded-md font-semibold text-slate-900
                h-8 xl:h-9 p-1 xl:p-2 text-sm xl:text-base w-[100%]
                        dark:bg-gray-200 dark:focus:bg-white
                        bg-white focus:bg-white'
                required
                type='password'
                onChange={(e) => setCurrentPassword(e.target.value)}
                value={currentPassword}
            />
          </label>
          {/* new password */}
          <label className='flex flex-col space-y-2'>
            <span className='text-xs sm:text-sm font-mono'>New Password:</span>
            <input 
                className='rounded-md font-semibold text-slate-900
                h-8 xl:h-9 p-1 xl:p-2 text-sm xl:text-base w-[100%]
                        dark:bg-gray-200 dark:focus:bg-white
                        bg-white focus:bg-white'
                required
                type='password'
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
            />
          </label>
          {/* confirm password */}
          <label className='flex flex-col space-y-2'>
            <span className='text-xs sm:text-sm font-mono'>Confirm Password:</span>
            <input 
                className='rounded-md font-semibold text-slate-900
                h-8 xl:h-9 p-1 xl:p-2 text-sm xl:text-base w-[100%]
                        dark:bg-gray-200 dark:focus:bg-white
                        bg-white focus:bg-white'
                required
                type='password'
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
            />
          </label>
        </div>
        {!isPending && <button 
          className='dark-btn duration-100 ease-linear transition-all dark:bg-amber-400 dark:text-slate-900 bg-gray-100 dark:hover:bg-amber-500 hover:bg-cyan-300 py-3 px-6 text-xs sm:text-sm xl:text-base w-[100%] mx-auto'>
            Update Password
        </button>}
        {isPending && <button 
          disabled
          className='dark-btn duration-100 ease-linear transition-all dark:bg-amber-400 dark:text-slate-900 bg-gray-100 dark:hover:bg-amber-500 hover:bg-cyan-300 py-3 px-6 text-xs sm:text-sm xl:text-base w-[100%] mx-auto animate-pulse'>
            Updating...
        </button>}
        {error && <div className='error'>{error}</div>}
      </form>
    </div>
  )
}
