import React, { useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import ChangeEmailSmall from './ChangeEmailSmall'
import ChangePasswordSmall from './ChangePasswordSmall'

export default function ChangeEmailPasswordSmall() {
  const [emailOpen, setEmailOpen] = useState(true)
  const [passwordOpen, setPasswordOpen] = useState(false)

  const emailClick = () => {
    setEmailOpen(true)
    setPasswordOpen(false)
  }

  const passwordClick = () => {
    setEmailOpen(false)
    setPasswordOpen(true)
  }
  return (
    <div className='h-[39rem] w-[100%] space-y-2 py-2 px-2 dark:bg-[#202225] bg-[#f8f9fa] rounded-lg shadow-xl'>
      {/* upper bar */}
      <div className='flex justify-between space-x-1 w-full p-1 rounded-lg dark:bg-[#36393e] bg-[#e9ecef] shadow h-[3.5rem]'>
        <div
          onClick={emailClick} 
          className= {`duration-200 rounded-lg p-2 w-full cursor-pointer flex justify-center items-center space-x-2
                      ${emailOpen ? 'bg-fuchsia-700 w-[60%] text-gray-50' : 'hover:bg-fuchsia-700 hover:text-gray-50 w-[40%]'}`}>
          <h2 className='text-center font-mono hidden sm:block'>Email</h2>
          <AiOutlineMail className='h-5 w-5' />
        </div>
        <div
          onClick={passwordClick} 
          className= {`duration-200 rounded-lg p-2 w-full cursor-pointer flex justify-center items-center space-x-2 
                      ${passwordOpen ? 'bg-fuchsia-700 w-[60%] text-gray-50' : 'hover:bg-fuchsia-700 hover:text-gray-50 w-[40%]'}`}>
          <h2 className='text-center font-mono hidden sm:block'>Password</h2>
          <RiLockPasswordLine className='h-5 w-5' />
        </div>
      </div>
      {/* lower contents */}
      {/* email */}
      <div className={`flex justify-between h-[89%] dark:bg-[#36393e] bg-[#e9ecef] rounded-lg p-1 sm:p-2
                      ${emailOpen ? 'block' : 'hidden'}`}>
        <div className='w-full h-full overflow-hidden'>
            <ChangeEmailSmall />
        </div>
      </div>
      {/* password */}
      <div className={`flex justify-between h-[89%] dark:bg-[#36393e] bg-[#e9ecef] rounded-lg p-1 sm:p-2
                      ${passwordOpen ? 'block' : 'hidden'}`}>
        <div className='w-full h-full overflow-hidden'>
            <ChangePasswordSmall />
        </div>
      </div>
    </div>
  )
}
