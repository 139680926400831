import React, { useState } from 'react'
import ChangeEmail from './ChangeEmail'
import pic_1 from '../assets/changeEmail_2.png'
import pic_2 from '../assets/changePassword_2.png'
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import ChangePassword from './ChangePassword'

export default function ChangeEmailPassword() {
  const [emailOpen, setEmailOpen] = useState(true)
  const [passwordOpen, setPasswordOpen] = useState(false)

  const emailClick = () => {
    setEmailOpen(true)
    setPasswordOpen(false)
  }

  const passwordClick = () => {
    setEmailOpen(false)
    setPasswordOpen(true)
  }

  return (
    <div className='space-y-2 dark:bg-[#202225] bg-[#f8f9fa] rounded-lg p-3 shadow-xl
                    h-[30rem] w-[50rem] xl:h-[38rem] xl:w-[65rem] '>
      {/* upper bar */}
      <div className='flex justify-between space-x-1 w-full p-1 rounded-lg dark:bg-[#36393e] bg-[#e9ecef] shadow
                      h-[3rem] xl:h-[4rem]'>
        <div
          onClick={emailClick} 
          className= {`duration-200 rounded-lg p-2 w-full cursor-pointer flex justify-center items-center space-x-2
                      ${emailOpen ? 'bg-fuchsia-700 w-[58%] text-gray-50' : 'hover:bg-fuchsia-700 hover:text-gray-50 w-[42%]'}`}>
          <h2 className='text-center font-mono'>Change Email</h2>
          <AiOutlineMail className='h-5 w-5' />
        </div>
        <div
          onClick={passwordClick} 
          className= {`duration-200 rounded-lg p-2 w-full cursor-pointer flex justify-center items-center space-x-2 
                      ${passwordOpen ? 'bg-fuchsia-700 w-[56%] text-gray-50' : 'hover:bg-fuchsia-700 hover:text-gray-50 w-[40%]'}`}>
          <h2 className='text-center font-mono'>Change Password</h2>
          <RiLockPasswordLine className='h-5 w-5' />
        </div>
      </div>
      {/* lower contents */}
      {/* email */}
      <div className={`flex justify-between h-[88%] w-full dark:bg-[#36393e] bg-[#e9ecef] rounded-lg p-2
                      ${emailOpen ? 'block' : 'hidden'}`}>
        <div className='w-[58%] overflow-hidden'>
            <ChangeEmail />
        </div>
        <img
          className='h-[22rem] w-[20rem] mt-[2rem] -mr-[0rem]
                     xl:h-[28rem] xl:w-[25rem]'
          src={pic_1} 
          alt='' 
        />
      </div>
      {/* password */}
      <div className={`flex justify-between h-[88%] w-full dark:bg-[#36393e] bg-[#e9ecef] rounded-lg p-2
                      ${passwordOpen ? 'block' : 'hidden'}`}>
        <img
          className='h-[22rem] w-[21rem] mt-[2rem] -ml-[0.5rem]
                     xl:h-[26.5rem] xl:w-[24rem] xl:mt-[2.5rem]'
          src={pic_2} 
          alt='' 
        />
        <div className='w-[56%] overflow-hidden'>
            <ChangePassword />
        </div>
      </div>
    </div>
  )
}
