import React from 'react'
import Sidebar from '../components/Sidebar'
import SidebarSmall from '../components/SidebarSmall'
import { useWindowWidth }  from '../hooks/useWindowWidth'
import { useAuthContext } from '../hooks/useAuthContext'
import TeamMembers from '../components/TeamMembers'
import TradeIdeas from '../components/TradeIdeas'
import TradeIdeasSmall from '../components/TradeIdeasSmall'


export default function Dashboard() {
  //use the useWindowWidth hook
  const { windowWidth } = useWindowWidth()

  //use the useAuthContext hook
  const { user } = useAuthContext()


  return (
    <div className='flex h-screen pages-background'>
      {user && <div>
        {windowWidth >= 1280 && <Sidebar />}
        {windowWidth < 1280 && <SidebarSmall />}
      </div>}
      <div className='contents-div-container
                      p-1 pt-3
                      sm:p-1 sm:pt-3
                      xl:p-3 xl:pt-6'>
        {windowWidth >= 1280 && <TradeIdeas />}
        {windowWidth < 1280 && <TradeIdeasSmall />}
      </div>
      {user && <div>
        {windowWidth >= 640 && <TeamMembers />}
      </div>}

    </div>
  )
}
