import React, { useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { useFirestore } from '../hooks/useFirestore'
import { useCollection } from '../hooks/useCollection'
import { useNotification } from '../hooks/useNotification'
import { TiDelete } from 'react-icons/ti'
import { timestamp } from "../firebase/config"
import Select from 'react-select'
import '../pages/Create.css'
import { v4 } from 'uuid'

const direction_options = [
    { value: 'LONG', label: 'LONG' },
    { value: 'SHORT', label: 'SHORT' },
  ]

export default function AddTradeIdea() {
  const [ticker, setTicker] = useState('')
  const [direction, setDirection] = useState('')
  const [entryPrice, setEntryPrice] = useState('')
  const [priceTarget, setPriceTarget] = useState('')
  const [additionalNotes, setAdditionalNotes] = useState('')
  const [popupOpen, setPopupOpen] = useState(true)

  //use the useAuthContext hook
  const { user } = useAuthContext()

  //use the useCollection hook
  const { documents } = useCollection('ideas') 

  //use the useFirestore hook
  const { updateDocument } = useFirestore('ideas')

  //use the useNotification hook
  const dispatch = useNotification()

  const handleNewNotification = () => {
    dispatch({
      //type: "ADD-STRATEGY", //optional! for styling purposes only
      title: "New Trade Ideas Added!",
      message: "GREAT! Successfully added a new Trade Idea!"
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    //create the person who created the Trade Idea
    const createdBy = {
        displayName: user.displayName,
        photoURL: user.photoURL,
        id: user.uid
    }

    //create the Trade Idea Object for database
    const newTradeIdea = {
        id: v4(),
        ticker: ticker,
        direction: direction.value,
        entryPrice: entryPrice,
        priceTarget: priceTarget,
        additionalNotes: additionalNotes,
        createdBy,
        createdAt: timestamp.fromDate(new Date()),
    }

    //ADD the Trade Idea Object to database
    await updateDocument ('M8XcaYVb1JCGT8O9A67D', {items:[...documents[0].items, newTradeIdea]})

    //close the Popup window
    setPopupOpen(false)

    // fire a Notification
    handleNewNotification()

  }

  return (
    <>
    {popupOpen &&
      <div className='flex flex-col rounded-xl overflow-auto shadow-overlay
                    dark:bg-slate-800 bg-slate-200
                      -mt-[6rem] h-[38rem] w-[20rem]
                      sm:-mt-[9rem] sm:h-[39rem] sm:w-[25rem]
                      lg:-mt-[3rem] lg:h-[39rem] lg:w-[25rem]
                      xl:-mt-[10rem] xl:h-[39rem] xl:w-[25rem]'>
        <button
          onClick={() => setPopupOpen(false)} 
          className='dark:text-red-300 text-red-400 ml-auto z-20 dark:hover:text-pink-500 hover:text-red-600 duration-200 p-1'>
          <TiDelete className='h-8 w-8' />
        </button>
        <div className='flex flex-col space-y-3 py-3 px-9 -mt-[1.5rem]'>
          <h2 className='text-base lg:text-lg font-bold'>Add New Trade Idea</h2>
          <form 
              onSubmit={handleSubmit}
              className='flex flex-col space-y-6'>
              {/* ticker */}
              <label className='flex flex-col space-y-1'>
                <span className='text-xs sm:text-sm font-mono'>Ticker:</span>
                <input 
                    className='rounded-md font-semibold text-slate-900 uppercase
                            h-7 sm:h-8 p-1 text-xs w-[100%]
                            dark:bg-gray-200 dark:focus:bg-white
                            bg-white focus:bg-white'
                    required
                    type='text'
                    onChange={(e) => setTicker(e.target.value)}
                    value={ticker}
                />
              </label>

              {/* direction */}
              <label className='flex flex-col space-y-1'>
                <span className='text-xs sm:text-sm font-mono'>LONG or SHORT:</span>
                <Select 
                    className='h-9 w-[100%] font-semibold text-xs rounded-lg text-slate-900'
                    onChange={(option) => setDirection(option)}
                    options={direction_options}
                />
              </label>

              {/* entry price */}
              <label className='flex flex-col space-y-1'>
                <span className='text-xs sm:text-sm font-mono'>Entry Price ($):</span>
                <input 
                    className='rounded-md font-semibold text-slate-900
                            h-7 sm:h-8 p-1 text-xs w-[100%]
                            dark:bg-gray-200 dark:focus:bg-white
                            bg-white focus:bg-white'
                    required
                    type='text'
                    onChange={(e) => setEntryPrice(e.target.value)}
                    value={entryPrice}
                />
              </label>

              {/* target */}
              <label className='flex flex-col space-y-1'>
                <span className='text-xs sm:text-sm font-mono'>Price Target ($):</span>
                <input 
                    className='rounded-md font-semibold text-slate-900
                            h-7 sm:h-8 p-1 text-xs w-[100%]
                            dark:bg-gray-200 dark:focus:bg-white
                            bg-white focus:bg-white'
                    required
                    type='text'
                    onChange={(e) => setPriceTarget(e.target.value)}
                    value={priceTarget}
                />
              </label>

              {/* additional notes */}
              <label className='flex flex-col space-y-1 w-[100%]'>
                <span className='create-span-title'>Additional Notes:</span>
                <textarea 
                className='min-h-[6rem] rounded-md font-semibold text-slate-900
                            h-8 p-2 text-xs w-[100%]
                            dark:bg-gray-200 dark:focus:bg-white
                            bg-white focus:bg-white'
                type='text'
                onChange={(e) => setAdditionalNotes(e.target.value)}
                value={additionalNotes}
                required
                ></textarea>
              </label>
              
              {/* button */}
              <button 
              className='dark-btn duration-100 ease-linear transition-all
                      dark:bg-gray-200 dark:text-slate-900 bg-gray-50 dark:hover:bg-amber-400 hover:bg-cyan-400
                          py-3 text-base w-[100%] mx-auto'
              >
              <p>Add</p>
              </button>
          </form>
        </div>
      </div>
    }
    </>
  )
}
