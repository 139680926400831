import React from 'react'

export default function MyAvatar({ photoPath }) {
  return (
    <div className='inline-block w-[69px] h-[69px] rounded-full overflow-hidden ring-4 ring-green-400'>
        <img 
            src={photoPath} 
            className="w-full h-full" 
            alt='avatar' 
        />
    </div>
  )
}
