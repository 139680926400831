import React from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useCollection } from '../hooks/useCollection';
import { useFirestore } from '../hooks/useFirestore'
import { GrFormAdd } from 'react-icons/gr'
import { useNotification } from '../hooks/useNotification';
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import _ from 'lodash';
import './TradeIdeas.css'
import Popup from 'reactjs-popup';
import AddTradeIdea from './AddTradeIdea';


export default function TradeIdeas() {
  //use the useCollection hook
  const { error, documents } = useCollection('ideas') 

  //use the useFirestore hook
  const { updateDocument } = useFirestore('ideas')

  //use the useNotification hook
  const dispatch = useNotification()

  const handleNewNotification = () => {
    dispatch({
      type: "DELETE-TRADEIDEAS",
      title: "Deleting Trade Idea...",
      message: "This Trade Idea will be deleted within 24h!"
    })
  }

  function delay(delayInms) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(2);
      }, delayInms);
    });
  } 

  const handleDragEnd = async ({ destination, source }) => {
    console.log("from: ", source)
    console.log("to: ", destination)

    // CASE: drag and drop out of the container
    if(!destination) {
      return
    }
    // CASE: drag and drop into the same place
    if(destination.index === source.index && destination.droppableId === source.droppableId) {
      return
    }
    if(destination.droppableId === source.droppableId) {
      return
    }

    //create a copy of the item that is being dragged curretly
    const itemCopy = {...documents[source.droppableId].items[source.index]}
    console.log("currently being dragged: ", itemCopy)
    //console.log(documents[destination.droppableId].id)

    const itemToMove = {
      id: documents[source.droppableId].items[source.index].id,
      ticker: documents[source.droppableId].items[source.index].ticker,
      direction: documents[source.droppableId].items[source.index].direction,
      entryPrice: documents[source.droppableId].items[source.index].entryPrice,
      priceTarget: documents[source.droppableId].items[source.index].priceTarget,
      additionalNotes: documents[source.droppableId].items[source.index].additionalNotes,
      createdAt: documents[source.droppableId].items[source.index].createdAt,
      createdBy: documents[source.droppableId].items[source.index].createdBy,
    }

    // DELETE the Dragged Item from "source" array and make a COPY of the NEW array
    const newArray = documents[source.droppableId].items.filter(eachItem => eachItem.id !== documents[source.droppableId].items[source.index].id)
    console.log("new array after remove: ", newArray)

    // REPLACE the source array with the NEW array
    updateDocument (documents[source.droppableId].id, {items: newArray})

    if (destination.droppableId === '3') {
      updateDocument ('dlU7eIbMZLEei4nNMCul', {items:[...documents[destination.droppableId].items, itemToMove]})
      handleNewNotification()
      await delay(9000000)
      await updateDocument ('dlU7eIbMZLEei4nNMCul', {items: []})
    } else {
      // ADD the Dragged Item to the "destination" array
      updateDocument (documents[destination.droppableId].id, {items:[...documents[destination.droppableId].items, itemToMove]})
    }
    return
  }

  return (
    <div className='flex flex-col space-y-1 lg:w-[98%] xl:w-[95%] min-h-full mx-auto'>
      <div>
        <Popup
          trigger={<button className='dark-btn popup-btn'>
                    <div className='flex space-x-1 justify-center items-center'>
                      <GrFormAdd className='lg:h-7 lg:w-7 xl:h-8 xl:w-8' />
                      <p>New Trade Idea</p>
                    </div>
                   </button>}
          modal
        >
          <AddTradeIdea />
        </Popup>
      </div>
      {error && <div className='error'>{error}</div>}
      {documents && <div className='flex w-[100%] justify-center space-x-3 xl:space-x-5'>
        <DragDropContext onDragEnd={handleDragEnd}>
          {_.map(documents, (data, key) => {
            //console.log("data: ", data.items)
            //console.log("key: ",key.toString())

            return (
              <div key={key.toString()} className='w-[100%] flex flex-col items-center space-y-1 py-3 pb-9 '>
                <h2 className={`drag-drop-title ${data.title === "Delete Ideas" ? 'dark:bg-rose-700 bg-red-300' : 'dark:bg-[#202225] bg-[#f8f9fa]'} `}>
                  {data.title}
                </h2>
                <Droppable droppableId={key.toString()}>
                  {(provided) => {
                    return(
                      <div
                        className={`droppable-col px-3 pt-1 pb-6 ${data.title === "Delete Ideas" ? 'dark:bg-rose-900 bg-red-200 opacity-70' : 'dark:bg-[#202225] bg-[#f8f9fa]'}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {data.items.map((eachTradeIdea, index) => {
                          return (
                            <Draggable key={eachTradeIdea.id} index={index} draggableId={eachTradeIdea.id}>
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    className={`mt-2 xl:mt-3 px-4 py-3 flex flex-col rounded-xl shadow-lg group 
                                                ${snapshot.isDragging && 'dark:bg-amber-500 bg-cyan-400'}
                                                ${eachTradeIdea.direction === 'LONG' ? 'dark:bg-emerald-500 bg-emerald-400' : 'dark:bg-red-400 bg-red-400'}`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className='flex flex-col items-end -mt-[0.4rem] -mr-2 text-stone-900 dark:text-stone-100 group-hover:translate-x-[-0.3rem] ease-linear duration-200'>
                                      <p className='text-[0.5rem]'>created:</p>
                                      <p className='text-[0.5rem] -mt-1 font-medium text-fuchsia-600 dark:text-fuchsia-700'>{formatDistanceToNow(eachTradeIdea.createdAt.toDate(), { addSuffix: true })}</p>
                                    </div>
                                    <div className='flex flex-col -mt-[1rem] group-hover:translate-x-[0.3rem] ease-linear duration-200'>
                                      <p className='text-xs xl:text-sm'>Ticker: <span className='text-sm xl:text-base font-semibold uppercase'>{eachTradeIdea.ticker}</span></p>
                                      <p className='text-xs xl:text-sm'>Direction: <span className='text-sm xl:text-base font-semibold'>{eachTradeIdea.direction}</span></p>
                                      <p className='text-xs xl:text-sm'>Entry Price: <span className='text-sm xl:text-base font-semibold'>${eachTradeIdea.entryPrice}</span></p>
                                      <p className='text-xs xl:text-sm'>Price Target: <span className='text-sm xl:text-base font-semibold'>${eachTradeIdea.priceTarget}</span></p>
                                    </div>
                                  </div>
                                )
                              }}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              </div>
            )
          })}
        </DragDropContext>
      </div>}
    </div>
  )
}
