import React from 'react'
import { useState } from "react"
import { timestamp } from "../firebase/config"
import { useAuthContext } from '../hooks/useAuthContext'
import { useFirestore } from '../hooks/useFirestore'
import GroupAvatars from './GroupAvatars'
import formatDistanceToNow from 'date-fns/formatDistanceToNow' 

export default function StrategyComments({ selectedStrategy }) {
  const [newComment, setNewComment] = useState('')

  //use the useAuthContext hook
  const { user } = useAuthContext()

  //use the useFirestore hook
  const { updateDocument, response } = useFirestore('strategies')

  const handleSubmit = async (e) => {
    e.preventDefault()

    //create the new Comment Object
    const commentToAdd = {
        displayName: user.displayName,
        photoURL: user.photoURL,
        content: newComment,
        createdAt: timestamp.fromDate(new Date()),
        id: Math.random()
    }
    await updateDocument(selectedStrategy.id, {
        comments: [...selectedStrategy.comments, commentToAdd]
    })
    if(!response.error) {
        setNewComment('')
    }
  }

  return (
    <div className='bg-white text-slate-900 dark:text-gray-100 dark:bg-[#202225] rounded-lg shadow-xl p-5 pb-8'>
      <h4 className='text-lg font-mono font-semibold text-emerald-500 dark:text-emerald-400'>Group Comments: </h4>
      {/* past comments */}
      <ul className='flex flex-col space-y-5 mt-3'>
        {selectedStrategy.comments.length === 0 && <p className='text-xs font-mono font-thin text-gray-300 dark:text-gray-500'>No comments yet. Add your first comment below...</p>}
        {selectedStrategy.comments.length > 0 && selectedStrategy.comments.map((eachComment) => (
            <li 
              className='p-4 bg-[#e9ecef] dark:bg-[#36393e] rounded-xl shadow-lg'
              key={eachComment.id}
            >
              <div className='flex items-center space-x-2'>
                  <GroupAvatars photoPath={eachComment.photoURL} />
                  <p className='font-mono font-semibold text-sm lg:text-base dark:text-slate-200'>{eachComment.displayName}</p>
              </div>
              <div className='mt-1'>
                  <p className='text-xs font-medium text-fuchsia-700 dark:text-fuchsia-300'>
                    {formatDistanceToNow(eachComment.createdAt.toDate(), { addSuffix: true })}
                  </p>
              </div>
              <div className='mt-3'>
                  <p className='text-sm'>{eachComment.content}</p>
              </div>
            </li>
        ))}
      </ul>
      {/* add new comments */}
      <form 
        onSubmit={handleSubmit}
        className='border-t border-dotted border-t-slate-900 dark:border-t-gray-100 mt-9 lg:mt-12 w-full'
      >
        <label className='flex flex-col space-y-1 mt-2'>
            <span className='text-sm font-mono text-slate-900 dark:text-gray-300'>Add New Comments:</span>
            <textarea
              className='w-[100%] min-h-[5rem] rounded-lg shadow-md
                         text-sm
                       dark:bg-gray-200 dark:focus:bg-white dark:text-slate-900
                       bg-[#e9ecef] focus:bg-slate-100'
              required
              onChange={(e) => setNewComment(e.target.value)}
              value={newComment}
            ></textarea>
        </label>
        <button 
            className='dark-btn duration-100 ease-linear transition-all mt-6 text-sm lg:text-base
                       w-[100%] px-[1.2rem] py-1 md:px-[0.8rem]
                     dark:bg-gray-200 dark:text-slate-900 bg-gray-100 dark:hover:bg-amber-400 hover:bg-cyan-300'
                         >Add Comment</button>
      </form>
    </div>
  )
}
