import { useContext } from "react";
import { NotificationContext } from "../notification/NotificationProvider";
import {v4} from 'uuid'

export const useNotification = () => {
    const dispatch = useContext(NotificationContext)

    return (props) => {
        dispatch({
            type: "ADD_NOTIFICATION",
            payload: {
                id: v4(),
                ...props
            }
        })
    }
}