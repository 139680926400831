import React from 'react'
import { FaChartPie } from 'react-icons/fa';
import { MdOutlineSpaceDashboard, MdAttachMoney } from 'react-icons/md'
import { VscAdd } from 'react-icons/vsc'
import { FiLogOut } from 'react-icons/fi'
import { AiFillSetting } from 'react-icons/ai'
import { NavLink } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout'
import MyAvatar from './MyAvatar';
import { useAuthContext } from '../hooks/useAuthContext';
import './Sidebar.css'

export default function Sidebar() {
  //use the useLogout hook
  const { logout, isPending } = useLogout()

  //use the useAuthContext hook
  const { user } = useAuthContext()

  return (
    <div className='fixed top-0 left-0 h-screen m-0 flex flex-col dark:bg-[#202225] bg-[#f8f9fa] shadow-lg
                    w-[12.6rem]
                    xl:w-[14rem]'
                    >
      <div className='flex flex-col h-screen justify-start pt-8 pb-10'>
        {/* users */}
        <div className='mx-auto flex flex-col items-center space-y-3'>
            <MyAvatar photoPath={user.photoURL} />
            <p className='font-bold tracking-wider text-lg'>Hey {user.displayName}</p>
        </div>
        {/* tabs */}
        <nav className='mt-[4.5rem]'>
          <ul className='space-y-6 pl-2'>
            {/* my dashboard */}
            <li>
              <NavLink 
                to='/dashboard' 
                className={(state) => (state.isActive 
                                          ? 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group nav-active-lg' 
                                          : 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group')}
              >
                <MdOutlineSpaceDashboard className='duration-200 ease-linear 
                                                    group-hover:rotate-90 group-hover:text-slate-900
                                                    h-6 w-6 
                                                    xl:h-7 xl:w-7' />
                <h2 className='sidebar-title-lg group-hover:text-slate-900'>
                  Trade Ideas
                </h2>
              </NavLink>
            </li>
            {/* whales portfolio */}
            <li>
              <NavLink 
                to='/whalestracker'
                className={(state) => (state.isActive 
                                        ? 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group nav-active-lg' 
                                        : 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group')}
              >
                <MdAttachMoney className='duration-200 ease-linear 
                                    group-hover:rotate-180 group-hover:text-slate-900
                                    h-6 w-6 
                                    xl:h-7 xl:w-7'  
                />
                <h2 className='sidebar-title-lg group-hover:text-slate-900'>
                  Whales Tracker
                </h2>
              </NavLink>
            </li>
            {/* all strategies -- "Project.js" Page */}
            <li>
              <NavLink 
                to='/allstrategies'
                className={(state) => (state.isActive 
                                        ? 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group nav-active-lg' 
                                        : 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group')}
              >
                <FaChartPie className='duration-200 ease-linear 
                                    group-hover:rotate-180 group-hover:text-slate-900
                                    h-6 w-6 
                                    xl:h-7 xl:w-7'  
                />
                <h2 className='sidebar-title-lg group-hover:text-slate-900'>
                  All Strategies
                </h2>
              </NavLink>
            </li>
            {/* create strategy */}
            <li>
              <NavLink 
                to='/create'
                className={(state) => (state.isActive 
                                          ? 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group nav-active-lg' 
                                          : 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group')}
              >
                <VscAdd className='duration-200 ease-linear 
                                    group-hover:rotate-90 group-hover:text-slate-900
                                    h-6 w-6 
                                    xl:h-7 xl:w-7'  
                />
                <h2 className='sidebar-title-lg group-hover:text-slate-900'>
                  Create Strategy
                </h2>
              </NavLink>
            </li>
           {/* settings */}
           <li>
              <NavLink 
                to='/settings'
                className={(state) => (state.isActive 
                                        ? 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group nav-active-lg' 
                                        : 'flex justify-start items-center space-x-3 px-3 py-2 cursor-pointer hover:bg-gray-200 hover:rounded-l-full duration-200 transition-all ease-linear group')}
              >
                <AiFillSetting className='duration-200 ease-linear 
                                    group-hover:rotate-180 group-hover:text-slate-900
                                    h-6 w-6 
                                    xl:h-7 xl:w-7'  
                />
                <h2 className='sidebar-title-lg group-hover:text-slate-900'>
                  Settings
                </h2>
              </NavLink>
            </li>
          </ul>
        </nav>

        {/* logout */}
        {!isPending && <button 
          onClick={logout}
          className='flex justify-start items-center space-x-3 mx-2 px-3 py-2 cursor-pointer mt-auto
                      hover:bg-gray-200 hover:rounded-full hover:shadow-2xl
                        duration-200 transition-all ease-linear group'
                        >
            <FiLogOut className='duration-200 ease-linear 
                                 group-hover:translate-x-1 group-hover:text-slate-900
                                 h-6 w-6 
                                 xl:h-8 xl:w-8'  
                                 />
            <h2 className='font-medium duration-200 ease-linear 
                           group-hover:translate-x-1 group-hover:text-slate-900
                           text-base
                           xl:text-xl'
                           >Log Out</h2>
        </button>}
        {isPending && <button 
          disabled
          className='flex justify-start items-center space-x-3 mx-2 px-3 py-2 cursor-pointer mt-auto
                      hover:bg-gray-200 hover:rounded-full hover:shadow-2xl
                        duration-200 transition-all ease-linear group'
                        >
            <FiLogOut className='duration-200 ease-linear 
                                 group-hover:translate-x-1 group-hover:text-slate-900
                                 h-6 w-6 
                                 xl:h-8 xl:w-8'  
                                 />
            <h2 className='font-semibold duration-200 ease-linear 
                           group-hover:translate-x-1 group-hover:text-slate-900
                           text-base
                           xl:text-xl'
                           >Logging Out...</h2>
        </button>}
      </div>

    </div>
  )
}


