import React from 'react'
import { FaMoon, FaSun } from 'react-icons/fa'
import { useDarkMode } from '../hooks/useDarkMode'

export default function DarkLightModeBtnWithHook() {
  //use the useDarkMode hook
  const [darkTheme, setDarkTheme] = useDarkMode();

  const handleMode = () => setDarkTheme(!darkTheme);

  return (

    <button 
        onClick={handleMode}
        type='button'
    >
        {(!darkTheme === true) && (<FaMoon className='w-7 h-7  text-slate-900 hover:text-cyan-500 duration-200 hover:scale-110' />)}
        {(!darkTheme === false) && (<FaSun className='w-7 h-7  text-slate-50 hover:text-yellow-400 duration-200 hover:scale-110' />)}
    </button>

  )
}
