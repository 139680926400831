//this is COMPONENT
import React, { useEffect, useState } from 'react'
import './Notification.css'

export default function Notification(props) {
  const [width, setWidth] = useState(0)
  const [intervalID, setIntervalID] = useState(null)
  const [exit, setExit] = useState(false)

  const handleStartTimer = () => {
    const id = setInterval(() => {
        setWidth((prevWidth) => {
            if(prevWidth < 100) {
                return prevWidth + 0.5
            }
            clearInterval(id)
            return prevWidth
        })
    }, 20)//it will run every 20 ms //in total, the notification will stay for 4s (20 * 100/0.5)

    setIntervalID(id)
  }

  const handlePauseTime = () => {
    clearInterval(intervalID)
  }

  const handleCloseNotification = () => {
    handlePauseTime()
    setExit(true)
    setTimeout(() => {
      props.dispatch({
        type: "REMOVE_NOTIFICATION",
        id: props.id
      })
    }, 400)
  }

  useEffect(() => {
    if(width === 100) {
        handleCloseNotification()
    }
  }, [width])

  useEffect(() => {
    handleStartTimer()
  }, [])

  return (
    <div
      onMouseEnter={handlePauseTime}
      onMouseLeave={handleStartTimer} 
      className={`notification-item notification-style ${exit ? "notification-exit" : ""}`}
      >
      <h2 className='text-sm xl:text-base px-3 text-gray-50 dark:text-slate-900 font-semibold'>{props.title}</h2>
      <p className='text-xs xl:text-sm px-3 text-gray-200 dark:text-slate-800 font-light'>{props.message}</p>
      <div 
        className='h-[0.4rem] mt-2 rounded-xl bg-slate-50'
        style={{width: `${width}%`}}
      ></div>
    </div>
  )
}
